import React from 'react';
import {Navigate} from 'react-router-dom';
import {useRoles} from './RoleProvider';

export const ProtectedRoute = ({requiredRoles, children}) => {
    const roles = useRoles();
    const hasRequiredRole = requiredRoles.some(role => roles.includes(role));
    if (!hasRequiredRole) {
        return <Navigate to="/unauthorized" replace/>;
    }
    return children;
};
