import React from 'react';

const styles = {
    container: {
        textAlign: 'center',
        padding: '50px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        maxWidth: '500px',
        margin: '100px auto',
    },
    heading: {
        fontSize: '36px',
        color: '#e74c3c',
        marginBottom: '20px',
    },
    message: {
        fontSize: '16px',
        color: '#555',
        marginBottom: '20px',
    },
    link: {
        textDecoration: 'none',
        color: '#3498db',
        fontWeight: 'bold',
    },
};
export default function UnauthorizedPage() {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Access Denied</h1>
            <p style={styles.message}>
                Sorry, you do not have the necessary permissions to view this page.
            </p>
            <p style={styles.message}>
                Please contact your system administrator or the support team to request access.
            </p>
            <a href="/" style={styles.link}>Return to Home</a>
        </div>
    )
}
