import TableContainer from "@mui/material/TableContainer";
import {Box, Paper} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import React, {useEffect, useState} from "react";
import {StyledTableCell, StyledTableRow} from "../../util/constants";

export default function App(props) {
    const [requestBody, setRequestBody] = useState();

    useEffect(() => {
        console.log('props', JSON.parse(props.requestBody));
        setRequestBody(JSON.parse(props.requestBody));
    }, [props]);

    return (<div>
        {(requestBody && requestBody.acctInfo && requestBody.policyInfo) && (
            <Box sx={{display: 'flex'}}>
                <TableContainer component={Paper} sx={{
                    padding: '1rem',
                    marginBottom: '0.5rem',
                    alignItems: 'center',
                    verticalAlign: "middle"
                }}>
                    <Table sx={{minWidth: 200, width: "50%"}} size="small" aria-label="customized table">
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell align="left">Policy Type</StyledTableCell>
                                <StyledTableCell textAlign="right" rowSpan={1}>
                                    {requestBody.policyInfo.homeOwnersLine.policyType}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell align="left">Insured Name</StyledTableCell>
                                <StyledTableCell textAlign="right" rowSpan={1}>
                                    {requestBody.acctInfo.acctHolder.firstName}&nbsp;
                                    {requestBody.acctInfo.acctHolder.lastName}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell align="left">Insured Address</StyledTableCell>
                                <StyledTableCell textAlign="right" rowSpan={1}>
                                    {requestBody.policyInfo.homeOwnersLine.policyAddress.address1},<br/>
                                    {requestBody.policyInfo.homeOwnersLine.policyAddress.address2 && (
                                        <p>{requestBody.policyInfo.homeOwnersLine.policyAddress.address2},<br/></p>
                                    )}
                                    {requestBody.policyInfo.homeOwnersLine.policyAddress.city},<br/>
                                    {requestBody.policyInfo.homeOwnersLine.policyAddress.county},&nbsp;
                                    {requestBody.policyInfo.homeOwnersLine.policyAddress.state}.&nbsp;
                                    {requestBody.policyInfo.homeOwnersLine.policyAddress.zip}.
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell align="left">Agent ID</StyledTableCell>
                                <StyledTableCell textAlign="right" rowSpan={1}>
                                    {requestBody.policyInfo.producerCode}
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>)
        }
    </div>)
}