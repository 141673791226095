import React, {useEffect, useState} from 'react';
import {MaterialReactTable} from 'material-react-table';
import {Box, Button} from "@mui/material";

export default function App(props) {
    const [tableData, setTableData] = useState([]);
    const [grouping, setGrouping] = useState([{id: 'category'}]);
    const [isShowingMatched, setIsShowingMatched] = useState(false);

    useEffect(() => {
        if (props?.carrierData && props.carrierData.coveragesComparison && props.carrierData.wlmComparison && props.quotePremium) {
            const coverageComparison = buildCoverageComparisonData(props.carrierData?.coveragesComparison);

            const wlmComparison = buildWlmComparisonData(props.carrierData?.wlmComparison);
            wlmComparison.forEach((element) => coverageComparison.push(element));

            const premiumComparision = buildPremiumComparableFields(props?.carrierData, props.quotePremium);
            premiumComparision.forEach((element) => coverageComparison.push(element));

            const nonComparableFields = buildNonComparableFields(props.carrierData?.nonComparableFields);
            nonComparableFields.forEach((element) => coverageComparison.push(element));

            if (coverageComparison && coverageComparison.length === 0) {
                handleRefreshClick();
            } else {
                setTableData(coverageComparison);
            }
        }
    }, [props.carrierData]);

    const buildPremiumComparableFields = (input, quotePremium) => {
        return [createEntry(
            'Premium',
            input?.carrierInfo.totalPrem,
            quotePremium.adjustedPrem,
            'Premium',
            input.isPremiumComparable
        )];
    }

    const buildNonComparableFields = (input) => {
        const result = [];
        if (input) {
            if (input.nonComparableFieldsList) {
                input.nonComparableFieldsList.forEach(item => {
                    result.push(createEntry(
                        item.key,
                        '', // Assuming no carrierValue for these fields
                        item.rqValue,
                        'Miscellaneous'
                    ));
                });
            }
            if (input.nonComparableCoverageList) {
                input.nonComparableCoverageList.forEach(item => {
                    result.push(createEntry(
                        item.key,
                        item.carrierValue,
                        item.rqValue,
                        'Miscellaneous'
                    ));
                });
            }
        }
        return result;
    }

    const createEntry = (name, carrierValue, rqValue, category, isSatisfied = false) => ({
        name,
        carrierValue,
        rqValue: rqValue !== undefined ? rqValue : '',
        category,
        isSatisfied: isSatisfied
    });

    const buildCoverageComparisonData = (input, showAll = false) => {
        return input
            .filter(comparison =>
                showAll ? true : comparison.satisfied === false
            )
            .map(comparison => {
                return {
                    name: comparison.key,
                    carrierValue: comparison.carrierValue,
                    rqValue: comparison.rqValue,
                    category: 'Coverages',
                    isSatisfied: comparison.satisfied
                }
            });
    }

    const buildWlmComparisonData = (input, showAll = false) => {
        let result = [];
        const keys = Object.keys(input);

        // Extract unique categories from keys
        const categories = [...new Set(keys.map(key => key.replace(/^(carrier|rq|comparable)/, '').replace(/^\w/, c => c.toUpperCase()).replace(/^\w{3}/, c => c.toLowerCase())))];
        categories.forEach(category => {
            const carrierKey = `carrier${category.charAt(0).toUpperCase() + category.slice(1)}`;
            const rqKey = `rq${category.charAt(0).toUpperCase() + category.slice(1)}`;
            const comparableKey = `comparable${category.charAt(0).toUpperCase() + category.slice(1)}`;
            result.push({
                name: category.charAt(0).toUpperCase() + category.slice(1),
                carrierValue: input[carrierKey],
                rqValue: input[rqKey],
                category: 'WLM',
                isSatisfied: input[comparableKey]
            });
        });
        if (showAll === false) {
            result = result.filter(element => !element.isSatisfied);
        }
        return result;
    }

    const columns = (carrierName) => [
        {
            header: 'Comparable Element',
            accessorKey: 'name',
            enableGrouping: false,
        },
        {
            header: `Carrier (${carrierName})`,
            accessorKey: 'carrierValue',
            enableGrouping: false,
        },
        {
            header: 'Citizen\'s Value',
            accessorKey: 'rqValue',
            enableGrouping: false,
        },
        {
            header: 'Category',
            accessorKey: 'category',
        },
    ];

    const CustomToolbar = ({onRefreshClick, isShowingMatched, onCloseClick}) => (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, margin: 'auto'}}>
            <Button sx={{
                backgroundColor: '#0066a1', color: 'white', '&:hover': {
                    backgroundColor: '#0066a1', color: 'white'
                }
            }} onClick={onRefreshClick} color="primary">
                {isShowingMatched ? 'Show UnMatched' : 'Show All'}
            </Button>
            <Button sx={{
                marginLeft: '1rem'
            }} onClick={onCloseClick} color="error">
                Close
            </Button>
        </Box>
    );

    const handleRefreshClick = () => {
        setIsShowingMatched(!isShowingMatched);
    };

    useEffect(() => {
        if (props?.carrierData && props.carrierData.coveragesComparison && props.carrierData.wlmComparison) {
            let coverageComparison = buildCoverageComparisonData(props.carrierData?.coveragesComparison, isShowingMatched);
            coverageComparison = coverageComparison.sort((a, b) => a.isSatisfied - b.isSatisfied);
            const wlmComparison = buildWlmComparisonData(props.carrierData?.wlmComparison, isShowingMatched);
            wlmComparison.forEach((element) => coverageComparison.push(element));
            const premiumComparision = buildPremiumComparableFields(props?.carrierData, props.quotePremium);
            premiumComparision.forEach((element) => coverageComparison.push(element));
            const nonComparableFields = buildNonComparableFields(props.carrierData?.nonComparableFields);
            nonComparableFields.forEach((element) => coverageComparison.push(element));
            setTableData(coverageComparison);
        }
    }, [isShowingMatched]);

    const getRowProps = ({row}) => {
        let backgroundColor;
        if (!row.getIsGrouped()) {
            if (row.original.category === 'Miscellaneous') {
                backgroundColor = 'rgba(255, 255, 255, 255)';
            } else {
                if (row.original.isSatisfied) {
                    backgroundColor = 'rgba(0, 255, 0, 0.2)';
                } else {
                    backgroundColor = 'rgba(255, 0, 0, 0.2)';
                }
            }
            return {
                style: {
                    backgroundColor
                }
            };
        } else {
            return {};
        }
    };

    const handleCloseClick = () => {
        props?.toggleOpenClose(false)
    };

    const table = {
        columns: columns(props?.carrierData?.carrierInfo?.carrierName),
        data: tableData,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                enableResizing: true,
            },
        },
        enableColumnResizing: true,
        enableGrouping: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        enablePagination: false,
        enableGlobalFilter: false,
        enableDensityToggle: false,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableFilters: false,
        enableHiding: false,
        enableSorting: false,
        pageSize: tableData.length,
        state: grouping,
        onGroupingChange: setGrouping,
        renderTopToolbarCustomActions: () => (
            <CustomToolbar
                onRefreshClick={handleRefreshClick}
                isShowingMatched={isShowingMatched}
                onCloseClick={handleCloseClick}
            />
        ),
        initialState: {
            density: 'compact',
            expanded: true, //expand all groups by default
            grouping: ['category'], //an array of columns to group by by default (can be multiple)
            pagination: {pageIndex: 0, pageSize: 20},
            sorting: [{id: 'category', desc: false}], //sort by state by default
        },
        muiToolbarAlertBannerChipProps: {color: 'primary'},
        muiTableContainerProps: {sx: {maxHeight: 700}},
        muiTableBodyRowProps: getRowProps
    };

    return <div>
        (
        <div>
            <MaterialReactTable {...table} />
            <style jsx>{`
              .MuiToolbar-root .MuiPaper-elevation {
                display: none;
              }
            `}</style>
            )
        </div>
        )
    </div>;
}