import React, { useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import ReactJson from "react18-json-view";
import "react18-json-view/src/style.css";
import { NavLink, useNavigate } from "react-router-dom";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomizedDialogs(props) {
  const [open, setOpen] = useState(props.openState);
  const [requestObj, setRequestObj] = useState(props?.jsonToUse?.Request || {});
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleCloseDialog(false);
  };

  const handleCheckEligibility = () => {
    setOpen(false);
    props.handleCloseDialog(false);
    const componentState = {
      requestId: props.viewData?.id,
      mitRoofCover:
        props.jsonToUse.Request?.policyInfo?.homeOwnersLine?.dwelling
          ?.mitRoofCover || null,
      mitOpenProt:
        props.jsonToUse.Request?.policyInfo?.homeOwnersLine?.dwelling
          ?.mitOpenProt || null,
      policyType:
        props.jsonToUse.Request?.policyInfo?.homeOwnersLine?.policyType || null,
    };
    localStorage.setItem("items", JSON.stringify(componentState));
    navigate("/carrier-form");
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.openState}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {`ID: ${props.viewData?.id || ""}`}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <ReactJson src={props?.jsonToUse || {}} />
          </Typography>
        </DialogContent>
        <DialogActions>
          {props.jsonToUse?.Request !== null && (
            <Button
              autoFocus
              onClick={handleCheckEligibility}
              variant="contained"
            >
              Check Eligibility
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default CustomizedDialogs;
