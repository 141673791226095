import {Box, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

export default function Error404() {
    return (
        <>
            <Box sx={{width: '100%'}}>
                <Typography variant="h5" gutterBottom align="left">
                    Page Not Found!
                </Typography>

                <Typography variant="string" gutterBottom align="left">
                    <br/><br/>
                    The page your are looking is not available.<br/>
                    Go to <Link to="/">HomePage</Link>
                </Typography>


            </Box>
        </>
    );
}