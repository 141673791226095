import {loginRequest} from "../../config/authentication/authConfig";
import {MsalAuthenticationTemplate} from "@azure/msal-react";
import {InteractionType} from "@azure/msal-browser";
import {Outlet} from "react-router-dom";

export default function ProtectedAppLayoutPage() {
    return (<>
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
        >
            <Outlet />
        </MsalAuthenticationTemplate>
    </>);
}