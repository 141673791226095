import React from "react";
import MaterialReactTable from "material-react-table";
import {MenuItem} from "@mui/material";
import {useNavigate} from "react-router-dom";

const VendorRenewalTable = ({
                                headers,
                                data
                            }) => {
    const navigate = useNavigate();

    const handleUpdateConfiguration = async (rowData) => {
        navigate('/vendor-renewal-config?uuid=' + rowData.uuid);
    };

    return (
        <div style={{width: "100%"}}>
            <MaterialReactTable
                columns={headers}
                data={data}
                enableRowActions
                enableStickyHeader
                isMultiSortEvent={() => false}
                enableSorting={false}
                enableFilters={false}
                displayColumnDefOptions={{
                    "mrt-row-actions": {
                        header: "Actions", //change header text
                    },
                }}
                positionActionsColumn="last"
                renderRowActionMenuItems={({row}) => [
                    <MenuItem key="update" onClick={() => handleUpdateConfiguration(row.original)}>
                        Update Configuration
                    </MenuItem>,
                ]}
                enableColumnResizing
                enableRowVirtualization
                muiTableContainerProps={{sx: {maxHeight: "500px"}}}
                enableColumnOrdering
                enablePinning
            />
        </div>
    );
};

export default VendorRenewalTable;