import {Button, Grid, TextField} from "@mui/material";
import React, {useState} from "react";
import Swal from "sweetalert2";

export default function SearchCriteria({
  handleGetSearchResults,
  setRows,
  searchForm,
  setSearchForm,
  handleReset,
}) {
  const [errorMessage, setErrorMessage] = useState();

  const handleSearch = (event) => {
    setRows(null);
    setErrorMessage("");
    event.preventDefault();
    if (inputValidations()) {
      handleGetSearchResults(searchForm);
    }
  };

  const handleUserInput = (event) => {
    setSearchForm({ ...searchForm, [event.target.name]: event.target.value });
  };

  const inputValidations = () => {
    let errorMessage = [];
    let validationPassed = true;
    if (
      !(
        searchForm.zip ||
        searchForm.county ||
        searchForm.producerCode ||
        searchForm.createdBeforeDate ||
        searchForm.createdAfterDate
      )
    ) {
      errorMessage.push("Input needed!");
      validationPassed = false;
    }
    if (
      (searchForm.createdAfterDate && !searchForm.createdBeforeDate) ||
      (!searchForm.createdAfterDate && searchForm.createdBeforeDate)
    ) {
      errorMessage.push("Both date criteria needed to proceed!");
      validationPassed = false;
    }

    if (
      searchForm.createdAfterDate &&
      searchForm.createdBeforeDate &&
      searchForm.createdAfterDate > searchForm.createdBeforeDate
    ) {
      errorMessage.push(
        "Creation End Date cannot be greator than Creation start Date!"
      );
      validationPassed = false;
    }
    if (
      (searchForm.zip || searchForm.county || searchForm.producerCode) &&
      !searchForm.createdBeforeDate &&
      !searchForm.createdAfterDate
    ) {
      errorMessage.push(
        "Please provide Created After and Created Before Dates"
      );
      validationPassed = false;
    }
    if (!validationPassed) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Oops!",
        text: errorMessage.toString(),
        showConfirmButton: true,
      });
    }
    return validationPassed;
  };

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} s={6} md={4} lg={2}>
          <TextField
            id="zip"
            name="zip"
            label="Zip"
            variant="outlined"
            value={searchForm?.zip}
            onChange={handleUserInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} s={6} md={4} lg={2}>
          <TextField
            id="county"
            name="county"
            label="County"
            variant="outlined"
            fullWidth
            value={searchForm?.county}
            onChange={handleUserInput}
          />
        </Grid>
        <Grid item xs={12} s={6} md={4} lg={2}>
          <TextField
            id="producerCode"
            name="producerCode"
            label="Producer Code"
            variant="outlined"
            value={searchForm?.producerCode}
            onChange={handleUserInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} s={6} md={4} lg={2}>
          <TextField
            required
            onChange={handleUserInput}
            value={searchForm?.createdAfterDate}
            id="createdAfterDate"
            name="createdAfterDate"
            label="Created After"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} s={6} md={4} lg={2}>
          <TextField
            required
            value={searchForm?.createdBeforeDate}
            onChange={handleUserInput}
            id="createdBeforeDate"
            name="createdBeforeDate"
            label="Created Before"
            type="date"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} s={6} md={4} lg={2}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
            onClick={handleSearch}
          >
            Search
          </Button>
          <Button
            variant="contained"
            style={{ background: "grey", marginLeft: "0.5rem" }}
            onClick={() => {
              setErrorMessage(null);
              handleReset();
            }}
            size="large"
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
