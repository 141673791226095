import React, {createContext, useContext, useEffect, useState} from 'react';
import {loginRequest} from "./authConfig";
import useFetchWithMsal from "./useFetchWithMsal";
import AppService from "../../service/AppService";

export const RoleContext = createContext();

export const useRoles = () => {
    return useContext(RoleContext);
};

export const RoleProvider = ({children}) => {
    const [roles, setRoles] = useState([]);
    const {execute, isTokenAvailable} = useFetchWithMsal(loginRequest);

    useEffect(() => {
        // Fetch roles and set them in the context
        if (isTokenAvailable)
            fetchRoles(execute).then(setRoles);
    }, [isTokenAvailable]);

    const fetchRoles = async (execute) => {
        try {
            // Fetch roles from the service
            return await fetchRolesFromService(execute);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
        return null;
    };

    const fetchRolesFromService = async (execute) => {
        let userRole = '';
        await AppService().fetchUserRole(execute)
            .then((response) => {
                userRole = response;
            }).catch((err) => {
                console.error('no valid role')
            });
        return [userRole];
    };

    return (
        <RoleContext.Provider value={roles}>
            {children}
        </RoleContext.Provider>
    );
};