import React from 'react';
import { Box, Typography, Link } from "@mui/material";

export function LandingPage() {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box m={6}>
        <Typography variant="h5" gutterBottom align="left">
          <b>Integration Hub</b>
        </Typography>
        <Typography variant="string" gutterBottom align="left">
          <br />
          <br />
          The Florida Market Assistance Plan (FMAP) Online Referral Program was
          designed to assist consumers with finding property and casualty
          insurance coverage in the state of Florida. Through this program,
          consumer requests for coverage can be matched with licensed agents who
          may be able to make an offer of coverage through an authorized,
          private market insurer.
          <br />
          <br />
        </Typography>
        <Typography variant="string" gutterBottom align="left">
          Note: FMAP referrals must be placed with admitted market carriers.
          Agents are prohibited from placing these leads with Citizens Property
          Insurance Corporation or surplus lines carriers.
          <br />
          <br />
        </Typography>
        <Typography variant="h5" gutterBottom align="left">
          Consumer
        </Typography>
        <Typography variant="string" gutterBottom align="left">
          Following registration, series of questions regarding the property
          seeking coverage. The FMAP online program will match the record
          (containing property details you submitted) with licensed Florida
          agents and insurers. The agent and/or insurer will then call or email
          to determine eligibility and provide an offer, where applicable.
          Access to update record(s) is available at any time using the User ID
          and password supplied during registration.
          <br />
          <br />
        </Typography>
        <Typography variant="h5" gutterBottom align="left">
          Agents
        </Typography>
        <Typography variant="string" gutterBottom align="left">
          Once registration is approved, will be able to access FMAP's online
          program to locate consumers that match carrier(s) specific eligibility
          requirements. Can search the FMAP database real-time or use the
          program's automated search feature to notify when consumer requests
          match specific search criteria. Access to update record(s) will be
          available at any time using the User ID and password supplied during
          registration.
          <br />
          <br />
        </Typography>
      </Box>
      <Box display={"flex"}>
        <Typography variant="body2" align="center">
          {" "}
          <br />
          {"© "} {new Date().getFullYear()}{" "}
          <Link href="https://www.citizensfla.org/" target="_blank">
            Citizens Property Insurance Corporation
          </Link>
          {"."}
        </Typography>
      </Box>
    </Box>
  );
}