import React, {useEffect, useState} from "react";
import {Button, Grid, Paper, TextField, Tooltip} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import FormControl from "@mui/material/FormControl";
import Delete from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Swal from "sweetalert2";
import {
  coverageCPersonalPropertyViewOnly,
  defaultValuesAndschemaValidationObject,
  dwellingCHoTerm,
  dwellingCHoType,
  dwellingHoTerm,
  dwellingHoType,
  hurrDedHoTerm,
  hurrDedHoType,
  hurrDedValue,
  lossSettlementCoverageHidden,
  lossSettmentHoTerm,
  lossSettmentHoType,
  lossSettmentHoValue,
  ordLawCoverageViewOnly,
  ordLawHoTerm,
  ordLawHoType,
  ordLawValue,
  windMitigationHidden,
  wlmGisTerrain,
  wlmMitOpenProt,
  wlmMitRoofCover,
  wlmMitRoofDeckAtt,
  wlmMitRoofShape,
  wlmMitRoofWallConn,
  wlmMitSecWaterRes,
} from "../../util/commonValidations";
import {useNavigate} from "react-router-dom";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";

const Create = () => {
  const [schemaObj, setSchemaObj] = useState({});
  const [defaultValuesToUse, setDefaultValuesToUse] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [carriersArray, setCarriersArray] = useState([]);
  const [deletedIndex, setDeletedIndex] = useState([]);
  const [mitRoofCoverRequired, setMitRoofCoverRequired] = useState(false);
  const [mitOpenProtRequired, setMitOpenProtRequired] = useState(false);
  const [requestIdToUse, setRequestIdToUse] = useState("");
  const [policyTypeToUse, setPolicyTypeToUse] = useState("");
  const schema = Yup.object().shape(schemaObj);
  const { execute, isLoading } = useFetchWithMsal(loginRequest);
  const navigate = useNavigate();

  useEffect(() => {
    const componentState = JSON.parse(localStorage.getItem("items"));
    if (componentState.requestId && componentState.requestId !== "") {
      setRequestIdToUse(componentState.requestId);
      setMitOpenProtRequired(componentState.mitOpenProt !== null);
      setMitRoofCoverRequired(componentState.mitRoofCover !== null);
      setPolicyTypeToUse(componentState.policyType);
    } else {
      navigate("/");
    }
  });

  useEffect(() => {
    if (submitted === true) {
      setCarriersArray([]);
      setSubmitted(false);
      setDefaultValuesToUse({});
    }
  }, [submitted]);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValuesToUse,
    resolver: yupResolver(schema),
  });

  const carrierNameTitle = {
    0: watch("carrierName0"),
    1: watch("carrierName1"),
    2: watch("carrierName2"),
    3: watch("carrierName3"),
    4: watch("carrierName4"),
    5: watch("carrierName5"),
    6: watch("carrierName6"),
    7: watch("carrierName7"),
    8: watch("carrierName8"),
    9: watch("carrierName9"),
  };

  const addNewCarrier = () => {
    let index = carriersArray.length;
    const currentFormData = getValues();
    if (
      index > 0 &&
      (Object.values(currentFormData).includes() ||
        Object.keys(errors).length > 0)
    ) {
      return null;
    } else {
      if (index < 10) {
        const newCarriersArray = carriersArray;
        let newDeleteIndex = [];
        if (deletedIndex.length > 0) {
          index = deletedIndex[0];
          newDeleteIndex = deletedIndex.slice(1);
        }
        const defaultValuesAndSchemas = defaultValuesAndschemaValidationObject({
          currentIndex: index,
          mitOpenProt: mitOpenProtRequired,
          mitRoofCover: mitRoofCoverRequired,
          policyType: policyTypeToUse,
        });
        setSchemaObj({ ...schemaObj, ...defaultValuesAndSchemas.schemaObject });
        setDefaultValuesToUse({
          ...defaultValuesToUse,
          ...defaultValuesAndSchemas.newObj,
        });
        newCarriersArray.push(index);
        setCarriersArray(newCarriersArray);
        setDeletedIndex(newDeleteIndex);
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Carrier Limit Exceeded",
          text: "You are allowed to add only 10 carriers",
          showConfirmButton: true,
        });
      }
    }
  };

  const deleteCarrier = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        if (carriersArray.length === 1) {
          setDefaultValuesToUse({});
          setCarriersArray([]);
          setSubmitted(true);
          reset({});
          setSchemaObj({});
          setDeletedIndex([]);
        } else {
          const newDeletedIndexArray = deletedIndex;
          newDeletedIndexArray.push(index);
          const newCarrierList = carriersArray.filter(
            (carrier, i) => carrier !== index
          );
          setCarriersArray(newCarrierList);
          setDeletedIndex(newDeletedIndexArray);
        }
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Carrier has been deleted",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const onResetClick = () => {
    reset({ ...defaultValuesToUse });
  };

  const handleSubmission = (data) => {
    if (data === "add") {
      return null;
    } else {
      const carriersInfo = [];
      carriersArray.forEach((carrierIndex, i) => {
        let carrierObj = {};
        let wlmCoverageObj = {
          gisTerrain: data["wlmCoverageGisTerrain" + carrierIndex.toString()],
          mitRoofDeckAtt:
            data["wlmCoverageMitRoofDeckAtt" + carrierIndex.toString()],
          mitRoofShape:
            data["wlmCoverageMitRoofShape" + carrierIndex.toString()],
          mitRoofWallConn:
            data["wlmCoverageMitRoofWallConn" + carrierIndex.toString()],
          mitSecWaterRes:
            data["wlmCoverageMitSecWaterRes" + carrierIndex.toString()],
        };
        if (mitOpenProtRequired) {
          wlmCoverageObj["mitOpenProt"] =
            data["wlmCoverageMitOpenProt" + carrierIndex.toString()];
        }
        if (mitRoofCoverRequired) {
          wlmCoverageObj["mitRoofCover"] =
            data["wlmCoverageMitRoofCover" + carrierIndex.toString()];
        }
        carrierObj["carrierName"] =
          data["carrierName" + carrierIndex.toString()];
        carrierObj["carrierNaicCode"] =
          data["carrierNaicCode" + carrierIndex.toString()];

        carrierObj["totalPrem"] = data["totalPrem" + carrierIndex.toString()];

        carrierObj["dwellingCoverageA"] = {
          hoTerm: data["dwellingCoverageAHoTerm" + carrierIndex.toString()],
          hoType: data["dwellingCoverageAHoType" + carrierIndex.toString()],
          value: data["dwellingCoverageAValue" + carrierIndex.toString()],
        };
        if (coverageCPersonalPropertyViewOnly.includes(policyTypeToUse)) {
          carrierObj["dwellingCoverageC"] = {
            hoTerm: data["dwellingCoverageCHoTerm" + carrierIndex.toString()],
            hoType: data["dwellingCoverageCHoType" + carrierIndex.toString()],
            value: data["dwellingCoverageCValue" + carrierIndex.toString()],
          };
        }
        if (!lossSettlementCoverageHidden.includes(policyTypeToUse)) {
          carrierObj["lossSettmentCoverage"] = {
            hoTerm:
              data["lossSettmentCoverageHoTerm" + carrierIndex.toString()],
            hoType:
              data["lossSettmentCoverageHoType" + carrierIndex.toString()],
            value: data["lossSettmentCoverageValue" + carrierIndex.toString()],
          };
        }
        carrierObj["hurrDedCoverage"] = {
          hoTerm: data["hurrDedCoverageHoTerm" + carrierIndex.toString()],
          hoType: data["hurrDedCoverageHoType" + carrierIndex.toString()],
          value: data["hurrDedCoverageValue" + carrierIndex.toString()],
        };
        if (ordLawCoverageViewOnly.includes(policyTypeToUse)) {
          carrierObj["ordLawCoverage"] = {
            hoTerm: data["ordLawCoverageHoTerm" + carrierIndex.toString()],
            hoType: data["ordLawCoverageHoType" + carrierIndex.toString()],
            value: data["ordLawCoverageValue" + carrierIndex.toString()],
          };
        }
        if (!windMitigationHidden.includes(policyTypeToUse)) {
          carrierObj["wlmCoverage"] = wlmCoverageObj;
        }
        // carrierObj["noPriorInsuranceSurcharge"] =
        //   data["noPriorInsuranceSurcharge" + carrierIndex.toString()] === "true"
        //     ? true
        //     : false;
        carriersInfo.push(carrierObj);
      });

      const carrierFormPayload = {
        requestId: requestIdToUse,
        carrierInfo: carriersInfo,
      };

      execute(
        "POST",
        "/api/quote-eligibility",
        JSON.stringify(carrierFormPayload)
      )
        .then((data) => {
          if (data && data.requestId === requestIdToUse) {
            if (data?.responseMessage?.includes("Quote is Eligible")) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Quote Eligibility Status",
                text: data.responseMessage,
                showConfirmButton: true,
              });
              setDefaultValuesToUse({});
              setCarriersArray([]);
              setSubmitted(true);
              reset({});
              setSchemaObj({});
              localStorage.setItem("items", JSON.stringify({}));
            } else {
              if (data?.errorMessages?.length > 0) {
                Swal.fire({
                  position: "center",
                  icon: "warning",
                  title: "Quote is not eligible",
                  text: data.errorMessages.toString(),
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  position: "center",
                  icon: "error",
                  title: "Failed to check eligibility",
                  text: "Unable to check eligibility, please try agin later",
                  showConfirmButton: true,
                });
              }
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Failed to check eligibility",
            html: err.message,
            showConfirmButton: true,
          });
        });
    }
  };
  return (
    <form
      onSubmit={handleSubmit(handleSubmission)}
      onReset={() => reset()}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          fontSize: "20px",
          fontWeight: 700,
          marginBottom: "1rem",
        }}
      >
        {`Carrier Form: ${requestIdToUse}`}
      </div>
      {carriersArray.length > 0 &&
        carriersArray.map((carrier, index) => {
          return (
            <>
              <Paper
                key={index}
                variant="elevation8"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      marginBottom: "0.5rem",
                    }}
                  >
                    {`Carrier: ${carrierNameTitle[carrier] || index + 1}`}
                  </div>
                  <Tooltip
                    title="Delete Carrier"
                    placement="bottom"
                    onClick={() => deleteCarrier(index)}
                  >
                    <Delete style={{ color: "#1565c0", cursor: "pointer" }} />
                  </Tooltip>
                </div>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"carrierName" + carrier.toString()}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          label="Carrier Name"
                          required
                          error={!!errors["carrierName" + carrier.toString()]}
                          helperText={
                            errors["carrierName" + carrier.toString()] &&
                            `${
                              errors["carrierName" + carrier.toString()].message
                            }`
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"carrierNaicCode" + carrier.toString()}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          label="Carrier Naic Code"
                          required
                          error={
                            !!errors["carrierNaicCode" + carrier.toString()]
                          }
                          helperText={
                            errors["carrierNaicCode" + carrier.toString()] &&
                            `${
                              errors["carrierNaicCode" + carrier.toString()]
                                .message
                            }`
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"totalPrem" + carrier.toString()}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          label="Total Premium"
                          required
                          error={!!errors["totalPrem" + carrier.toString()]}
                          helperText={
                            errors["totalPrem" + carrier.toString()] &&
                            `${
                              errors["totalPrem" + carrier.toString()].message
                            }`
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"noPriorInsuranceSurcharge" + carrier.toString()}
                      render={({ field }) => (
                        <FormControl
                          required
                          {...field}
                          error={
                            !!errors[
                              "noPriorInsuranceSurcharge" + carrier.toString()
                            ]
                          }
                        >
                          <FormLabel
                            id={
                              "noPriorInsuranceSurcharge" + carrier.toString()
                            }
                          >
                            No Prior Insurance Surcharge
                          </FormLabel>
                          <RadioGroup row>
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label="True"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="False"
                            />
                          </RadioGroup>
                          <FormHelperText>
                            {errors[
                              "noPriorInsuranceSurcharge" + carrier.toString()
                            ] &&
                              `${
                                errors[
                                  "noPriorInsuranceSurcharge" +
                                    carrier.toString()
                                ].message
                              }`}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid> */}
                </Grid>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginBottom: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  {"Dwelling Coverage A:"}
                </div>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"dwellingCoverageAHoTerm" + carrier.toString()}
                      render={({ field }) => (
                        <FormControl fullWidth {...field} required>
                          <InputLabel
                            id={"dwellingCoverageAHoTerm" + carrier.toString()}
                          >
                            Ho Term
                          </InputLabel>
                          <Select
                            {...field}
                            type="select"
                            label="Ho Term"
                            labelId={
                              "dwellingCoverageAHoTerm" + carrier.toString()
                            }
                          >
                            {dwellingHoTerm.map((item, i) => (
                              <MenuItem value={item} key={`${item}_${i}`}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"dwellingCoverageAHoType" + carrier.toString()}
                      render={({ field }) => (
                        <FormControl fullWidth {...field} required>
                          <InputLabel
                            id={"dwellingCoverageAHoType" + carrier.toString()}
                          >
                            Ho Type
                          </InputLabel>
                          <Select
                            {...field}
                            type="select"
                            label="Ho Type"
                            labelId={
                              "dwellingCoverageAHoType" + carrier.toString()
                            }
                          >
                            {dwellingHoType.map((item, i) => (
                              <MenuItem value={item} key={`${item}_${i}`}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"dwellingCoverageAValue" + carrier.toString()}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          {...field}
                          label="Value"
                          required
                          error={
                            !!errors[
                              "dwellingCoverageAValue" + carrier.toString()
                            ]
                          }
                          helperText={
                            errors[
                              "dwellingCoverageAValue" + carrier.toString()
                            ] &&
                            `${
                              errors[
                                "dwellingCoverageAValue" + carrier.toString()
                              ].message
                            }`
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                {coverageCPersonalPropertyViewOnly.includes(
                  policyTypeToUse
                ) && (
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginBottom: "0.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      {"Dwelling Coverage C:"}
                    </div>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"dwellingCoverageCHoTerm" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "dwellingCoverageCHoTerm" + carrier.toString()
                                }
                              >
                                Ho Term
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Ho Term"
                                labelId={
                                  "dwellingCoverageCHoTerm" + carrier.toString()
                                }
                              >
                                {dwellingCHoTerm.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"dwellingCoverageCHoType" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "dwellingCoverageCHoType" + carrier.toString()
                                }
                              >
                                Ho Type
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Ho Type"
                                labelId={
                                  "dwellingCoverageCHoType" + carrier.toString()
                                }
                              >
                                {dwellingCHoType.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"dwellingCoverageCValue" + carrier.toString()}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              {...field}
                              label="Value"
                              required
                              error={
                                !!errors[
                                  "dwellingCoverageCValue" + carrier.toString()
                                ]
                              }
                              helperText={
                                errors[
                                  "dwellingCoverageCValue" + carrier.toString()
                                ] &&
                                `${
                                  errors[
                                    "dwellingCoverageCValue" +
                                      carrier.toString()
                                  ].message
                                }`
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {!lossSettlementCoverageHidden.includes(policyTypeToUse) && (
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginBottom: "0.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      {"Loss Settlement Coverage:"}
                    </div>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={
                            "lossSettmentCoverageHoTerm" + carrier.toString()
                          }
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "lossSettmentCoverageHoTerm" +
                                  carrier.toString()
                                }
                              >
                                Ho Term
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Ho Term"
                                labelId={
                                  "lossSettmentCoverageHoTerm" +
                                  carrier.toString()
                                }
                              >
                                {lossSettmentHoTerm.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={
                            "lossSettmentCoverageHoType" + carrier.toString()
                          }
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "lossSettmentCoverageHoType" +
                                  carrier.toString()
                                }
                              >
                                Ho Type
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Ho Type"
                                labelId={
                                  "lossSettmentCoverageHoType" +
                                  carrier.toString()
                                }
                              >
                                {lossSettmentHoType.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={
                            "lossSettmentCoverageValue" + carrier.toString()
                          }
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "lossSettmentCoverageValue" +
                                  carrier.toString()
                                }
                              >
                                Value
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Value"
                                labelId={
                                  "lossSettmentCoverageValue" +
                                  carrier.toString()
                                }
                              >
                                {lossSettmentHoValue.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    marginBottom: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  {"Hurricane Deductable Coverage:"}
                </div>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"hurrDedCoverageHoTerm" + carrier.toString()}
                      render={({ field }) => (
                        <FormControl fullWidth {...field} required>
                          <InputLabel
                            id={"hurrDedCoverageHoTerm" + carrier.toString()}
                          >
                            Ho Term
                          </InputLabel>
                          <Select
                            {...field}
                            type="select"
                            label="Ho Term"
                            labelId={
                              "hurrDedCoverageHoTerm" + carrier.toString()
                            }
                          >
                            {hurrDedHoTerm.map((item, i) => (
                              <MenuItem value={item} key={`${item}_${i}`}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"hurrDedCoverageHoType" + carrier.toString()}
                      render={({ field }) => (
                        <FormControl fullWidth {...field} required>
                          <InputLabel
                            id={"hurrDedCoverageHoType" + carrier.toString()}
                          >
                            Ho Type
                          </InputLabel>
                          <Select
                            {...field}
                            type="select"
                            label="Ho Type"
                            labelId={
                              "hurrDedCoverageHoType" + carrier.toString()
                            }
                          >
                            {hurrDedHoType.map((item, i) => (
                              <MenuItem value={item} key={`${item}_${i}`}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} s={9} md={6} lg={3}>
                    <Controller
                      control={control}
                      name={"hurrDedCoverageValue" + carrier.toString()}
                      render={({ field }) => (
                        <FormControl fullWidth {...field} required>
                          <InputLabel
                            id={"hurrDedCoverageValue" + carrier.toString()}
                          >
                            Value
                          </InputLabel>
                          <Select
                            {...field}
                            type="select"
                            label="Value"
                            labelId={
                              "hurrDedCoverageValue" + carrier.toString()
                            }
                          >
                            {hurrDedValue.map((item, i) => (
                              <MenuItem value={item} key={`${item}_${i}`}>
                                {item}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                {ordLawCoverageViewOnly.includes(policyTypeToUse) && (
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginBottom: "0.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      {"ORD Law Coverage:"}
                    </div>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"ordLawCoverageHoTerm" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={"ordLawCoverageHoTerm" + carrier.toString()}
                              >
                                Ho Term
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Ho Term"
                                labelId={
                                  "ordLawCoverageHoTerm" + carrier.toString()
                                }
                              >
                                {ordLawHoTerm.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"ordLawCoverageHoType" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={"ordLawCoverageHoType" + carrier.toString()}
                              >
                                Ho Type
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Ho Type"
                                labelId={
                                  "ordLawCoverageHoType" + carrier.toString()
                                }
                              >
                                {ordLawHoType.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"ordLawCoverageValue" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={"ordLawCoverageValue" + carrier.toString()}
                              >
                                Value
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Value"
                                labelId={
                                  "ordLawCoverageValue" + carrier.toString()
                                }
                              >
                                {ordLawValue.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                {!windMitigationHidden.includes(policyTypeToUse) && (
                  <>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        marginBottom: "0.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      {"WLM Coverage:"}
                    </div>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"wlmCoverageGisTerrain" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "wlmCoverageGisTerrain" + carrier.toString()
                                }
                              >
                                Gis Terrain
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Gis Terrain"
                                labelId={
                                  "wlmCoverageGisTerrain" + carrier.toString()
                                }
                              >
                                {wlmGisTerrain.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {mitOpenProtRequired && (
                        <Grid item xs={12} s={9} md={6} lg={3}>
                          <Controller
                            control={control}
                            name={"wlmCoverageMitOpenProt" + carrier.toString()}
                            render={({ field }) => (
                              <FormControl
                                fullWidth
                                {...field}
                                required={mitOpenProtRequired}
                              >
                                <InputLabel
                                  id={
                                    "wlmCoverageMitOpenProt" +
                                    carrier.toString()
                                  }
                                >
                                  Mit Open Prot
                                </InputLabel>
                                <Select
                                  {...field}
                                  type="select"
                                  label="Mit Open Prot"
                                  labelId={
                                    "wlmCoverageMitOpenProt" +
                                    carrier.toString()
                                  }
                                >
                                  {wlmMitOpenProt.map((item, i) => (
                                    <MenuItem value={item} key={`${item}_${i}`}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      )}
                      {mitRoofCoverRequired && (
                        <Grid item xs={12} s={9} md={6} lg={3}>
                          <Controller
                            control={control}
                            name={
                              "wlmCoverageMitRoofCover" + carrier.toString()
                            }
                            render={({ field }) => (
                              <FormControl
                                fullWidth
                                {...field}
                                required={mitRoofCoverRequired}
                              >
                                <InputLabel
                                  id={
                                    "wlmCoverageMitRoofCover" +
                                    carrier.toString()
                                  }
                                >
                                  Mit Roof Cover
                                </InputLabel>
                                <Select
                                  {...field}
                                  type="select"
                                  label="Mit Roof Cover"
                                  labelId={
                                    "wlmCoverageMitRoofCover" +
                                    carrier.toString()
                                  }
                                >
                                  {wlmMitRoofCover.map((item, i) => (
                                    <MenuItem value={item} key={`${item}_${i}`}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={
                            "wlmCoverageMitRoofDeckAtt" + carrier.toString()
                          }
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "wlmCoverageMitRoofDeckAtt" +
                                  carrier.toString()
                                }
                              >
                                Mit Roof Deck Att
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Mit Roof Deck Att"
                                labelId={
                                  "wlmCoverageMitRoofDeckAtt" +
                                  carrier.toString()
                                }
                              >
                                {wlmMitRoofDeckAtt.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={"wlmCoverageMitRoofShape" + carrier.toString()}
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "wlmCoverageMitRoofShape" + carrier.toString()
                                }
                              >
                                Mit Roof Shape
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Mit Roof Shape"
                                labelId={
                                  "wlmCoverageMitRoofShape" + carrier.toString()
                                }
                              >
                                {wlmMitRoofShape.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={
                            "wlmCoverageMitRoofWallConn" + carrier.toString()
                          }
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "wlmCoverageMitRoofWallConn" +
                                  carrier.toString()
                                }
                              >
                                Mit Roof Wall Conn
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Mit Roof Wall Conn"
                                labelId={
                                  "wlmCoverageMitRoofWallConn" +
                                  carrier.toString()
                                }
                              >
                                {wlmMitRoofWallConn.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} s={9} md={6} lg={3}>
                        <Controller
                          control={control}
                          name={
                            "wlmCoverageMitSecWaterRes" + carrier.toString()
                          }
                          render={({ field }) => (
                            <FormControl fullWidth {...field} required>
                              <InputLabel
                                id={
                                  "wlmCoverageMitSecWaterRes" +
                                  carrier.toString()
                                }
                              >
                                Mit Sec Water Res
                              </InputLabel>
                              <Select
                                {...field}
                                type="select"
                                label="Mit Sec Water Res"
                                labelId={
                                  "wlmCoverageMitSecWaterRes" +
                                  carrier.toString()
                                }
                              >
                                {wlmMitSecWaterRes.map((item, i) => (
                                  <MenuItem value={item} key={`${item}_${i}`}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>
            </>
          );
        })}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "1rem",
          marginBottom: "1rem",
          marginTop: "1rem",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="add"
          onClick={addNewCarrier}
          style={{ background: "green", marginRight: "0.5rem" }}
          size="large"
        >
          Add New Carrier
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          disabled={carriersArray.length === 0}
        >
          Check Eligibility
        </Button>
        {/* <Button
          type="reset"
          variant="contained"
          style={{ background: "grey", marginLeft: "0.5rem" }}
          //onClick={onResetClick}
          size="large"
        >
          Clear
        </Button> */}
      </div>
    </form>
  );
};

export default Create;
