import React, {useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import {isValidJSON, parseError} from "../../config/react-routing";
import Loader from "../general/Loader";
import {ErrorPage} from "../general/ErrorPage";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import AppService from "../../service/AppService";

export default function QuoteSubmitPage() {
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [svcError, setSvcError] = useState();
  const [request, setRequest] = useState();
  const [response, setResponse] = useState();
  const { execute, isLoading } = useFetchWithMsal(loginRequest);
  const [toggleSwitch, setToggleSwitch] = useState(false);
  const [toggleSwitchLabel, setToggleSwitchLabel] = useState("Form");
  const [searchForm, setSearchForm] = useState({
    zip: "",
    county: "",
    producerCode: "",
    createdAfter: "",
    createdBefore: "",
    createdAfterDate: "",
    createdBeforeDate: "",
  });
  const handleReset = () => {
    setError(null);
    setSvcError(null);
    setRequest(null);
    setResponse(null);
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    setSvcError(null);
    if (!isValidInput()) {
      setError("Enter a valid JSON request!");
    } else {
      setError(null);
      AppService().createQuoteRequest(execute, request)
          .then((response) => {
            setRequest("");
            setResponse(response);
            setOpen(true);
          })
          .catch((error) => setSvcError(error));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isValidInput = () => {
    return !!(request && isValidJSON(request));
  };

  const handleSwitchChange = (data) => {
    const newToggleValue = data.target.checked;
    if (newToggleValue) {
      setToggleSwitchLabel("Payload");
    } else {
      setToggleSwitchLabel("Form");
    }
    setToggleSwitch(newToggleValue);
  };

  const handleUserInput = (event) => {
    setSearchForm({ ...searchForm, [event.target.name]: event.target.value });
  };
  return (
    <Box sx={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "0.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            fontSize: "22px",
            fontWeight: 700,
            marginBottom: "0.5rem",
          }}
        >
          Submit Quote
        </div>
        {/* <FormControlLabel
          control={
            <Switch
              checked={toggleSwitch}
              onChange={handleSwitchChange}
              name="toggle-switch-name"
            />
          }
          label={toggleSwitchLabel}
        /> */}
      </div>
      {isLoading && <Loader />}
      {!isLoading && !toggleSwitch && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            width: "60%",
            marginBottom: "1rem",
          }}
        >
          <TextField
            name="requestPayload"
            id="outlined-basic"
            label="Request Payload"
            variant="outlined"
            value={request}
            onChange={(event) => setRequest(event.target.value)}
            error={error}
            helperText={error}
            required
            multiline
            rows={15}
            fullWidth
            maxRows={Infinity}
            size="small"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "1rem",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem", background: "grey" }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </div>
        </div>
      )}
      {!isLoading && toggleSwitch && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} s={6} md={4} lg={2}>
            <TextField
              id="zip"
              name="zip"
              label="Zip"
              variant="outlined"
              value={searchForm?.zip}
              onChange={handleUserInput}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} s={6} md={4} lg={2}>
            <TextField
              id="county"
              name="county"
              label="County"
              variant="outlined"
              fullWidth
              value={searchForm?.county}
              onChange={handleUserInput}
            />
          </Grid>
          <Grid item xs={12} s={6} md={4} lg={2}>
            <TextField
              id="producerCode"
              name="producerCode"
              label="Producer Code"
              variant="outlined"
              value={searchForm?.producerCode}
              onChange={handleUserInput}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} s={6} md={4} lg={2}>
            <TextField
              onChange={handleUserInput}
              value={searchForm?.createdAfterDate}
              id="createdAfterDate"
              name="createdAfterDate"
              label="Created After"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} s={6} md={4} lg={2}>
            <TextField
              value={searchForm?.createdBeforeDate}
              onChange={handleUserInput}
              id="createdBeforeDate"
              name="createdBeforeDate"
              label="Created Before"
              type="date"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} s={6} md={4} lg={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              //onClick={handleSearch}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              style={{ background: "grey", marginLeft: "0.5rem" }}
              // onClick={() => {
              //   setErrorMessage(null);
              //   handleReset();
              // }}
              size="large"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Quote Response</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              &nbsp;&nbsp;&nbsp;<b>Request Id</b>:
              <span>{response?.requestId}</span>
              <br />
              &nbsp;&nbsp;&nbsp;<b>Adjusted Premium</b>:
              <span>${response?.adjustedPrem}</span>
              <br />
              &nbsp;&nbsp;&nbsp;<b>Policy Holder Surcharge</b>:
              <span>${response?.policyHolderSurcharge}</span>
              <br />
              &nbsp;&nbsp;&nbsp;<b>Emergency Assessment</b>:
              <span>${response?.emergencyAssessment}</span>
              <br />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {svcError && <ErrorPage message={parseError(svcError)} />}
    </Box>
  );
}