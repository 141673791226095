import * as Yup from "yup";

export const labelsArrayToUse = [
  "carrierName",
  "carrierNaicCode",
  "totalPrem",
  // "noPriorInsuranceSurcharge",
  "dwellingCoverageAHoTerm",
  "dwellingCoverageAHoType",
  "dwellingCoverageAValue",
  "dwellingCoverageCHoTerm",
  "dwellingCoverageCHoType",
  "dwellingCoverageCValue",
  "lossSettmentCoverageHoTerm",
  "lossSettmentCoverageHoType",
  "lossSettmentCoverageValue",
  "hurrDedCoverageHoTerm",
  "hurrDedCoverageHoType",
  "hurrDedCoverageValue",
  "ordLawCoverageHoTerm",
  "ordLawCoverageHoType",
  "ordLawCoverageValue",
  "wlmCoverageGisTerrain",
  "wlmCoverageMitOpenProt",
  "wlmCoverageMitRoofCover",
  "wlmCoverageMitRoofDeckAtt",
  "wlmCoverageMitRoofShape",
  "wlmCoverageMitRoofWallConn",
  "wlmCoverageMitSecWaterRes",
];

export const titlesToUse = [
  "Carrier Name",
  "Carrier Naic Code",
  "Total Prem",
  // "No Prior Insurance Surcharge",
  "Dwelling Coverage A HoTerm",
  "Dwelling Coverage A HoType",
  "Dwelling Coverage A Value",
  "Dwelling Coverage C HoTerm",
  "Dwelling Coverage C HoType",
  "Dwelling Coverage C Value",
  "Loss Settment Coverage HoTerm",
  "Loss Settment Coverage HoType",
  "Loss Settment Coverage Value",
  "Hurr Ded Coverage HoTerm",
  "Hurr Ded Coverage HoType",
  "Hurr Ded Coverage Value",
  "Ord Law Coverage HoTerm",
  "Ord Law Coverage HoType",
  "Ord Law Coverage Value",
  "Wlm Coverage Gis Terrain",
  "Wlm Coverage Mit Open Prot",
  "Wlm Coverage Mit Roof Cover",
  "Wlm Coverage Mit Roof Deck Att",
  "Wlm Coverage Mit Roof Shape",
  "Wlm Coverage Mit Roof Wall Conn",
  "Wlm Coverage Mit Sec Water Res",
];

export const updateCarrierDetails = ({ carriersList, labelsArray, data }) => {
  const updatedCarrierData = [];
  if (carriersList.length > 0) {
    carriersList.forEach((item, index) => {
      let updatedCarrierObj = {};
      labelsArray.forEach((label) => {
        const fieldTitle = label + index.toString();
        updatedCarrierObj[`${fieldTitle}`] = data[`${fieldTitle}`];
      });
      updatedCarrierData.push(updatedCarrierObj);
    });
  }
  return updatedCarrierData;
};

export const defaultValuesAndschemaValidationObject = ({
  currentIndex,
  mitOpenProt,
  mitRoofCover,
  policyType,
}) => {
  let newObj = {};
  let schemaObject = {};
  labelsArrayToUse.forEach((label, i) => {
    const fieldTitle = label + currentIndex.toString();
    newObj[`${fieldTitle}`] = "";
    if (
      (!ordLawCoverageViewOnly.includes(policyType) &&
        (label === "ordLawCoverageHoTerm" ||
          label === "ordLawCoverageHoType" ||
          label === "ordLawCoverageValue")) ||
      (lossSettlementCoverageHidden.includes(policyType) &&
        (label === "lossSettmentCoverageHoTerm" ||
          label === "lossSettmentCoverageHoType" ||
          label === "lossSettmentCoverageValue")) ||
      (windMitigationHidden.includes(policyType) &&
        (label === "Wlm Coverage Gis Terrain" ||
          label === "Wlm Coverage Mit Open Prot" ||
          label === "Wlm Coverage Mit Roof Cover" ||
          label === "Wlm Coverage Mit Roof Deck Att" ||
          label === "Wlm Coverage Mit Roof Shape" ||
          label === "Wlm Coverage Mit Roof Wall Conn" ||
          label === "Wlm Coverage Mit Sec Water Res")) ||
      (!coverageCPersonalPropertyViewOnly.includes(policyType) &&
        (label === "dwellingCoverageCHoTerm" ||
          label === "dwellingCoverageCHoType" ||
          label === "dwellingCoverageCValue"))
    ) {
      schemaObject[`${fieldTitle}`] = Yup.string()
        .label(titlesToUse[i])
        .trim()
        .nullable()
        .notRequired();
    } else if (label === "wlmCoverageMitOpenProt" && !mitOpenProt) {
      schemaObject[`${fieldTitle}`] = Yup.string()
        .label(titlesToUse[i])
        .trim()
        .nullable()
        .notRequired();
    } else if (label === "wlmCoverageMitRoofCover" && !mitRoofCover) {
      schemaObject[`${fieldTitle}`] = Yup.string()
        .label(titlesToUse[i])
        .trim()
        .nullable()
        .notRequired();
    } else {
      schemaObject[`${fieldTitle}`] = Yup.string()
        .label(titlesToUse[i])
        .trim()
        .required();
    }
  });
  return { newObj, schemaObject };
};

export const carrierFormSchema = ({
  carriersList,
  labelsArray,
  errorValidationList,
  data,
}) => {
  let newErrorValidationList = errorValidationList;
  if (carriersList.length > 0) {
    carriersList.forEach((item, index) => {
      labelsArray.forEach((label) => {
        const fieldTitle = label + index.toString();
        if (
          data[`${fieldTitle}`]?.trim() === "" ||
          data[`${fieldTitle}`] === undefined
        ) {
          newErrorValidationList[`${fieldTitle}`] = true;
        } else {
          newErrorValidationList[`${fieldTitle}`] = false;
        }
      });
    });
  }
  return newErrorValidationList;
};

export const dropDownValues = ({ type }) => {
  switch (type) {
    case "":
      return [];
    case "":
      return [];
    case "":
      return [];
    default:
      return [];
  }
};

export const dwellingHoType = ["HODW_Cov_A_HOE"];
export const dwellingHoTerm = ["HODW_Cov_A_Dwelling_HOE"];
export const dwellingHoValue = ["500", "2", "5"]; //$500, 2% 5%

export const dwellingCHoType = ["HODW_Cov_C_HOE"];
export const dwellingCHoTerm = ["HODW_Cov_C_Personal_Property_HOE"];

export const lossSettmentHoType = ["HODW_Property_Replace_Cost_Cov_HOE"];
export const lossSettmentHoTerm = ["HODW_Property_Replace_Cost_Opt_HOE"];
export const lossSettmentHoValue = ["Yes", "No"]; //not required

export const hurrDedHoTerm = ["HOLI_Hurricane_Cov_Deductible_HOE"];
export const hurrDedHoType = ["HOLI_Hurricane_Cov_HOE"];
export const hurrDedValue = ["500", "2", "5"];

export const ordLawHoTerm = ["HOLI_Ordinance_Law_Cov_Limit_HOE"];
export const ordLawHoType = ["HOLI_Ordinance_Law_Cov_HOE"];
export const ordLawValue = ["25", "50", "Excluded"]; //20%, 50%

export const wlmGisTerrain = ["B", "C"];
export const wlmMitOpenProt = [
  "Class A",
  "Class B",
  "Class C",
  "Unknown",
  "None",
];
export const wlmMitRoofCover = [
  "Unknown",
  "Non-FBC Equivalent (Level A)",
  "FBC Equivalent (Level B)",
  "Reinforced Concrete Roof Deck",
  "Non-FBC Equivalent",
  "FBC",
];
export const wlmMitRoofDeckAtt = [
  "Level A",
  "Level B",
  "Level C",
  "Level A (Wood or Other Deck)",
  "Level B (Metal Deck)",
  "Level C (Reinforced Concrete Roof Deck)",
  "Unknown",
  "Reinforced Concrete Roof Deck",
];
export const wlmMitRoofShape = ["Unknown", "Flat", "Hip", "Gable", "Other"];
export const wlmMitRoofWallConn = [
  "Toe Nail",
  "Clips",
  "Single Wraps",
  "Double Wraps",
  "Unknown",
];
export const wlmMitSecWaterRes = ["YES", "NO", "UNKNOWN"];

export const carrierReqRes = {
  requestObj: {
    requestId: "2a17106b-d752-46e9-9860-d5ce45a900e4",
    carrierInfo: [
      {
        carrierName: "Guico",
        carrierNaicCode: "104040",
        totalPrem: 5000,
        dwellingCoverageA: {
          hoType: "HODW_Cov_A_HOE",
          hoTerm: "HODW_Cov_A_Dwelling_HOE",
          value: "326500",
        },
        lossSettmentCoverage: {
          hoType: "HODW_Property_Replace_Cost_Cov_HOE",
          hoTerm: "HODW_Property_Replace_Cost_Opt_HOE",
          value: "Yes",
        },
        hurrDedCoverage: {
          hoType: "HOLI_Hurricane_Cov_HOE",
          hoTerm: "HOLI_Hurricane_Cov_Deductible_HOE",
          value: "2",
        },
        ordLawCoverage: {
          hoType: "HOLI_Ordinance_Law_Cov_HOE",
          hoTerm: "HOLI_Ordinance_Law_Cov_Limit_HOE",
          value: "25",
        },
        wlmCoverage: {
          mitRoofCover: "Non-FBC Equivalent",
          mitRoofDeckAtt: "Level A",
          mitRoofWallConn: "Toe Nail",
          mitSecWaterRes: "NO",
          gisTerrain: "C",
          mitRoofShape: "Hip",
          mitOpenProt: "Class A",
        },
        noPriorInsuranceSurcharge: true,
      },
    ],
  },
  responseObj: {
    requestId: "2a17106b-d752-46e9-9860-d5ce45a900e4",
    message: null,
    errorMessages: [
      "Not Comparable for Guico : Hurricane Deductible Coverage is not comparable. Citizen value: 10 Guico value:2",
    ],
    responseMessage:
      "Quote is not Eligible. Premium/Coverages are not matching with any carrier.",
  },
  errorMsg: null,
};

export const noCarrierReqRes = {
  requestObj: null,

  responseObj: null,

  errorMsg:
    "No Eligibility details found with this Id: 01884f81-d351-75c5-9894-bdabbb027be1",
};

export const lossSettlementCoverageHidden = ["DP1", "DP3D", "MDP1D", "MH03"];

export const ordLawCoverageViewOnly = ["HO3"];

export const windMitigationHidden = ["MH03", "MDP1D"];

export const coverageCPersonalPropertyViewOnly = ["HO6", "HW6"];

export const parseError = (err) => {
  let errorObject = err?.message;
  if (errorObject && JSON.parse(errorObject)?.errorMessage) {
    return JSON.parse(errorObject)?.errorMessage + " : " + JSON.parse(errorObject)?.additionalMessage;
  } else {
    return 'An error occurred!';
  }
}