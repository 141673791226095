import React, {useEffect, useState} from "react";
import {Button, Grid, Paper, TextField} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import {useAccount, useMsal} from "@azure/msal-react";
import Moment from "moment-timezone";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import {CampaignMaterialTable} from "./CampaignMaterialReactTable";
import Loader from "../general/Loader";
import {SegmentedMessage} from "sms-segments-calculator";

export default function ChartsPage() {
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tableData, setTableData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [inProgressRecords, setInProgressRecords] = useState(0);
    const [completedRecords, setCompletedRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const {execute, isLoading} = useFetchWithMsal(loginRequest);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [msalError, setMsalError] = useState(null);
    const [result, setResult] = useState();
    const [campaignNames, setCampaignNames] = useState([]);
    const [segments, setSegments] = useState(0);

    const searchForm = {
        campaignId: undefined,
        campaignName: "",
        startDate: "",
        startTime: "",
        campaignStatus: "Draft",
        //endDate: "",
        campaignMessage: "",
    };

    const [loadingIndicator, setLoadingIndicator] = useState(false);

    const schemaObject = Yup.object().shape({
        campaignName: Yup.string().trim().required(),
        campaignMessage: Yup.string().trim().required(),
        startDate: Yup.string()
            .trim()
            .required()
            .test(
                "is less than",
                "start date must be less than end date",
                (value, ctx) => {
                    const currentDate = Moment(Date.now()).format("YYYY-MM-DD");
                    if (value && value >= currentDate) {
                        return true;
                    } else {
                        return false;
                    }
                }
            )
    });

    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: searchForm,
        resolver: yupResolver(schemaObject),
    });

    const watchEndDate = watch("startDate");
    const watchCampaignName = watch("startDate");
    const campaignMessage = watch('campaignMessage');

    useEffect(() => {
        setSegments(new SegmentedMessage(campaignMessage));
    }, [campaignMessage]);
    useEffect(() => {
        localStorage.setItem("campaignDetails", JSON.stringify({}));
        if (!result && account) {
            instance
                .acquireTokenSilent({
                    scopes: loginRequest.scopes,
                    account: account,
                })
                .then((result) => {
                    console.log("Result Account Use effect", result);
                    setResult(result);
                })
                .catch((error) => {
                    console.log("Result Error", error.message);
                    setMsalError(setMsalError);
                });
        }
    }, [account, instance]);

    useEffect(() => {
        if (result) {
            getDetails();
        }
    }, [result]);

    const getDetails = () => {
        const payload = {
            limitedData: false,
        };
        setLoadingIndicator(true);

        execute("POST", "/api/campaign/getDetails", JSON.stringify(payload))
            .then((data) => {
                setLoadingIndicator(true);
                console.log("Get Data Campaign Details", data);
                if (data.campaignDetails && data.campaignDetails.length > 0) {
                    let updatedTableData = data.campaignDetails.map((item) => ({
                        ...item,
                        startTime: item.startDate,
                    }));
                    console.log("Get Data Campaign Details", updatedTableData);
                    setTableData(updatedTableData);
                    setTotalRecords(updatedTableData.length);
                    setCompletedRecords(data.completedCampaigns);
                    setInProgressRecords(data.inProgressCampaigns);
                    setTotalRecords(updatedTableData.length);
                    setPageNumber(1);
                    const campainDetails = data.campaignDetails;
                    const campaignArray = [];
                    if (campainDetails?.length > 0) {
                        campainDetails.forEach((item) => {
                            let objectToUse = {
                                key: item.campaignId,
                                value: item.campaignName,
                            };
                            campaignArray.push(objectToUse);
                        });
                    }
                    setCampaignNames(campaignArray);
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Campaign Details",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "No campaign details found",
                        text: "No campaign details",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Get Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get campaign details",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    const handleSubmitInput = (data) => {
        console.log("Submit Data", data);
        const postObject = Object.assign(
            {},
            {
                campaignName: data.campaignName,
                campaignMessage: data.campaignMessage,
                startDate: new Date(`${data.startDate} ${data.startTime}`).getTime(),
                campaignStatus: 'Draft',
                operationType: data.campaignId ? 'update' : 'create',
            }
        );
        if (data.campaignId) {
            postObject['campaignId'] = data.campaignId;
        }
        setLoadingIndicator(true);
        execute(
            "POST",
            "/api/campaign/create-update-campaign",
            JSON.stringify(postObject)
        )
            .then((data) => {
                console.log("Data", data);
                if (data.status === "Success") {
                    reset();
                    getDetails();
                    setLoadingIndicator(false);
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Failed",
                        text: data?.message || "Failed",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Insert Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };


    const handleCampaignJob = async (selectedCampaignId) => {
        const postObject = Object.assign(
            {},
            {
                campaignId: selectedCampaignId,
            }
        );
        setLoadingIndicator(true);
        await execute(
            "POST",
            `/api/campaign/startJob`,
            JSON.stringify(postObject)
        )
            .then((data) => {
                console.log("Data", data);
                if (data.status === "Success") {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: `Campaign Successfully Started`,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else if (data.status === 'Fail') {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Failed",
                        text: data?.errorMessages?.toString() || "Failed",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Insert Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };


    const handleCampaignPreFill = (rowData) => {
        reset();
        setValue('campaignId', rowData.campaignId);
        setValue('campaignMessage', rowData.campaignMessage);
        setValue('campaignName', rowData.campaignName);
        setValue('campaignStatus', rowData.campaignStatus);
        const date = new Date(rowData.startDate);
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        setValue('startDate', `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`);
        setValue('startTime', `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`);
        window.scrollTo(0, 0);
    };


    const headerColumns = [
        {
            header: "NAME",
            accessorKey: "campaignName",
            minSize: 50,
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "MESSAGE",
            accessorKey: "campaignMessage",
            minSize: 50,
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "Status",
            accessorKey: "campaignStatus",
            minSize: 50,
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "START DATE",
            accessorKey: "startDate",
            size: 50,
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({cell}) => {
                return <div>{Moment(cell.getValue()).format("YYYY-MM-DD")}</div>;
            },
        },
        {
            header: "START TIME",
            accessorKey: "startTime",
            size: 50,
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            Cell: ({cell}) => {
                return <div>{Moment(cell.getValue()).format("hh:mm a")}</div>;
            },
        }
    ];

    const campaignStatusOptions = ["Draft", "Approved", "In Progress"];
    const centerStyle = {
        margin: 'auto'
    };


    const wrapStyle = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'flex',
        top: 0
    }
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start",
            }}
        >
            {loadingIndicator && <div style={wrapStyle}>
                <div style={centerStyle}><Loader/></div>
            </div>}
            <div
                style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    color: "#2C2C2C",
                    marginBottom: "0.5rem",
                }}
            >
                New Campaign Form:
            </div>
            <form
                onSubmit={handleSubmit(handleSubmitInput)}
                onReset={() => reset()}
                style={{display: "flex", width: "100%"}}
            >
                <Paper
                    key={"campaignForm"}
                    variant="elevation8"
                    style={{
                        display: "flex",
                        padding: "1rem",
                        marginBottom: "1rem",
                        width: "98%",
                    }}
                >
                    <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={{xs: 1, sm: 2, md: 3}}
                    >
                        <Grid item xs={12} s={8} md={4} lg={3}>
                            <Controller
                                control={control}
                                name={"campaignName"}
                                render={({field}) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        label="Name"
                                        required
                                        error={!!errors.campaignName}
                                        helperText={
                                            errors.campaignName && "Name is required/invalid"
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} s={8} md={4} lg={3}>
                            <Controller
                                control={control}
                                name={"startDate"}
                                render={({field}) => (
                                    <TextField
                                        type="date"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...field}
                                        label="Start Date"
                                        required
                                        error={!!errors.startDate}
                                        helperText={
                                            errors.startDate && "Start Date is required/invalid"
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} s={8} md={4} lg={3}>
                            <Controller
                                control={control}
                                name={"startTime"}
                                render={({field}) => (
                                    <TextField
                                        type="time"
                                        halfWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        {...field}
                                        label="Start Time"
                                        required
                                        error={!!errors.startTime}
                                        helperText={
                                            errors.startTime && "Start Date is required/invalid"
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} s={8} md={8} lg={6}>
                            <Controller
                                control={control}
                                name={"campaignMessage"}
                                render={({field}) => (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        label="Message"
                                        required
                                        multiline
                                        rows={4}
                                        error={!!errors.campaignMessage}
                                        helperText={
                                            errors.campaignMessage ? "Message is required/invalid" : `Length: ${campaignMessage.length} characters, Segments: ${segments.segmentsCount}`
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} s={8} md={4} lg={3}>
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                height={"100%"}
                            >
                                <Button variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>
                                <Button
                                    variant="contained"
                                    style={{background: "grey", marginLeft: "0.5rem"}}
                                    type="reset"
                                >
                                    Reset
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </form>
            <div
                style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    color: "#2C2C2C",
                    marginBottom: "0.5rem",
                    marginTop: "0.5rem",
                }}
            >
                Available Campaigns:
            </div>
            <Box width={"100%"}>
                <CampaignMaterialTable
                    headers={headerColumns}
                    data={tableData}
                    handleChangePage={() => console.log("NextPage")}
                    totalRows={totalRecords}
                    enableFilter={true}
                    enableSort={true}
                    enableRowActions={false}
                    manualPagination={false}
                    enableEditing={true}
                    handleExecuteCampaign={handleCampaignJob}
                    handleCampaignEdit={handleCampaignPreFill}
                    uploadAction={true}
                    displayAllRows={false}
                />
            </Box>
        </div>
    );
}
