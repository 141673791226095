import React from "react";
import {Grid} from "@mui/material";
import {ErrorPage} from "../general/ErrorPage";

export default function RatingPayloadTile({ratingPayload, ratingErrorMessage}) {

    return (
      <main>
        {ratingErrorMessage && <ErrorPage message={ratingErrorMessage} />}
        {!ratingErrorMessage && (
          <Grid container spacing={2} style={{ overflow: "auto" }}>
            {ratingPayload?.quoteRequestOption1 && (
              <>
                <Grid item xs={6} md={6} lg={6} zeroMinWidth>
                  <b>Request 1</b>
                  <pre>{ratingPayload?.quoteRequestOption1}</pre>
                </Grid>
                <Grid item xs={6} md={6} lg={6} zeroMinWidth>
                  <b>Response 1</b>
                  <pre>
                    {ratingPayload?.quoteResponseOpton1?.replaceAll(
                      ">",
                      ">\r\n"
                    )}
                  </pre>
                </Grid>
              </>
            )}
            {ratingPayload?.quoteRequestOption2 && (
              <>
                <Grid item xs={6} md={6} lg={6} zeroMinWidth>
                  <b>Request 2</b>
                  <pre>{ratingPayload?.quoteRequestOption2}</pre>
                </Grid>
                <Grid xs={6} md={6} lg={6} zeroMinWidth>
                  <b>Response 2</b>
                  <pre>
                    {ratingPayload?.quoteResponseOpton2?.replaceAll(
                      ">",
                      ">\r\n"
                    )}
                  </pre>
                </Grid>
              </>
            )}
            {ratingPayload?.quoteRequestOption3 && (
              <>
                <Grid item xs={6} md={6} lg={6}>
                  <b>Request 3</b>
                  <pre>{ratingPayload?.quoteRequestOption3}</pre>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <b>Response 3</b>
                  <pre>
                    {ratingPayload?.quoteResponseOpton3?.replaceAll(
                      ">",
                      ">\r\n"
                    )}
                  </pre>
                </Grid>
              </>
            )}
          </Grid>
        )}
        <br />
        <br />
      </main>
    );
}