import {useCallback, useEffect, useState,} from 'react';

import {useAccount, useMsal} from "@azure/msal-react";
import {loginRequest} from "./authConfig";
import {hasTokenExpired} from "../react-routing";

export const baseApi = (process.env.REACT_APP_ENV === 'LOCAL') ? process.env.REACT_APP_URL : process.env.REACT_APP_API_BASE_URL;
const useFetchWithMsal = (msalRequest) => {
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoading, setIsLoading] = useState(false);
    const [msalError, setMsalError] = useState(null);
    const [result, setResult] = useState();

    useEffect(() => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                account: account
            }).then(result => setResult(result))
                .catch(error => setMsalError(setMsalError));
        }
    }, [account, instance]);

    /**
     * Execute a fetch request with the given options
     * @param {string} method: GET, POST, PUT, DELETE
     * @param {String} endpoint: The endpoint to call
     * @param {Object} data: The data to send to the endpoint, if any
     * @returns JSON response
     */
    const execute = async (method, endpoint, data = null, headerValues = null) => {
        if (msalError && process.env.REACT_APP_ENV !== "LOCAL") {
            throw new Error("Error occured - No token available!");
        }

        if (
            (result && result.accessToken) ||
            process.env.REACT_APP_ENV === "LOCAL"
        ) {
            let response = null;

            const headers = new Headers();
            const bearer = `Bearer ${result.accessToken}`;
            headers.append("Authorization", bearer);
            if (data) headers.append("Content-Type", "application/json");
            if (headerValues) {
                Object.entries(headerValues).forEach(entry => {
                    const [key, value] = entry;
                    headers.append(key, value);
                })
            }
            let options = {
                method: method,
                headers: headers,
                body: data ? data : null,
            };
            setIsLoading(true);
            response = await await fetch(baseApi + endpoint, options);
            if (response.status === 401) {
                if (hasTokenExpired(result?.accessToken)) {
                    instance
                        .acquireTokenSilent({
                            scopes: loginRequest.scopes,
                            account: account,
                        })
                        .then((result) => setResult(result))
                        .catch((error) => setMsalError(setMsalError));
                } else {
                    setIsLoading(false);
                    throw new Error({
                        message: JSON.stringify({
                            errorMessage: "An error occured. Please try again",
                        }),
                    });
                }
            }

            if (!response.ok) {
                setIsLoading(false);
                return response.text().then((text) => {
                    throw new Error(text);
                });
            }
            setIsLoading(false);
            return response.json();
        } else {
            throw new Error("Error occured - No token available!");
        }
    };

    return {
        isLoading,
        isTokenAvailable: result,
        execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
    };
};

export default useFetchWithMsal;