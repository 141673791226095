import Grid from "@mui/material/Grid";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import Loader from "../general/Loader";
import React, {useEffect, useState} from "react";
import VendorRenewalTable from "./VendorRenewalTable";
import ConfigurationRequestDialog from "./ConfigurationRequestDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AppService from "../../service/AppService";
import {centerStyle} from "../../util/constants";

const VendorRenewalConfigurationView = () => {

    const headerColumns = [
        {
            header: "VENDOR NAME",
            accessorKey: "vendorName",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "VENDOR ID",
            accessorKey: "uuid",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            }
        },
        {
            header: "AUTH TYPE",
            accessorKey: "authType",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "CARRIER ID",
            accessorKey: "carrierId",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "CARRIER NAME",
            accessorKey: "carrierName",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "FILTER CRITERIA",
            accessorKey: "configurationRequest",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff"
                },
            },
            Cell: (rowData) => {
                return <FontAwesomeIcon icon={faEye} size="lg" onClick={() => handleViewClick(rowData)}/>
            }
        }
    ];
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState({});
    const [jsonData, setJsonData] = useState({});
    const [tableData, setTableData] = React.useState([]);
    const {execute, isLoading, isTokenAvailable} = useFetchWithMsal(loginRequest);
    const [vendorRenewalErrorMessage, setVendorRenewalErrorMessage] = React.useState();
    const handleClose = () => {
        setOpenDialog(false);
    };
    const handleViewClick = async (rowData) => {
        const requestId = rowData?.row?.original?.uuid;
        if (requestId) {
            setOpenDialog(true);
            setSelectedRowData(rowData?.row?.original);
            setJsonData(JSON.parse(rowData?.row?.original?.configurationRequest));
        }
    };
    useEffect(() => {
        if (isTokenAvailable) {
            getAllVendorRenewalConfiguration();
        }
    }, [isTokenAvailable]);

    async function getAllVendorRenewalConfiguration() {
        AppService().getAllVendorConfigs(execute)
            .then((response) => {
                if (response instanceof Array) {
                    setVendorRenewalErrorMessage(undefined);
                    if (response.length > 0) {
                        setTableData(response);
                    } else {
                        setVendorRenewalErrorMessage('No Vendor Configuration is Created.');
                    }
                } else {
                    setVendorRenewalErrorMessage('Unable to fetch Vendor Renewal Configuration at this time.');
                }
            })
            .catch((err) => {
                console.error(err);
                setVendorRenewalErrorMessage('Unable to fetch Vendor Renewal Configuration at this time.');
            });
    }

    return (<Grid>
        <div style={centerStyle}>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    onClick={() => navigate('/vendor-renewal-config')}
                >
                    Create Vendor Renewal Configuration
                </Button>
            </Grid>

        </div>
        <div style={centerStyle}><Grid item lg={12}>
            {isLoading && <Loader/>}
        </Grid>
        </div>
        {!isLoading && tableData.length > 0 &&
            <div>
                <h2 style={centerStyle}>Vendor Renewal Configuration</h2>
                <ConfigurationRequestDialog
                    openState={openDialog}
                    viewData={selectedRowData}
                    handleCloseDialog={handleClose}
                    jsonToUse={jsonData}
                />
                <VendorRenewalTable
                    headers={headerColumns}
                    data={tableData}
                />
            </div>}
    </Grid>)
}

export default VendorRenewalConfigurationView;