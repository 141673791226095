import React from "react";
import {Outlet} from "react-router-dom";
import Navigation from "../navigation/Navigation";

export default function AppLayoutPage() {
  return (
    <Navigation>
      {/* <Header /> */}
      <main style={{ padding: "20px" }}>
        <Outlet />
      </main>
      {/* <Footer /> */}
    </Navigation>
  );
}