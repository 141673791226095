import React, {useCallback} from "react";
import Button from "@mui/material/Button";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../../config/authentication/authConfig";

export default function LoginButton() {
    const {instance} = useMsal();

    const handleSingin = useCallback(() => {
        instance.loginRedirect(loginRequest);
    }, [instance]);

    return (
        <Button
            key="loginbutton"
            onClick={handleSingin}
            sx={{my: 2, color: 'white', mr: 2, wordWrap: "normal", whiteSpace: "pre-wrap"}}
        >
            Sign&nbsp;in
        </Button>
    );
}