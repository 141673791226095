import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import ReactJson from "react18-json-view";
import "react18-json-view/src/style.css";
import {BootstrapDialog, BootstrapDialogTitle} from "./Dialog";

function ConfigurationRequestDialog(props) {
    const handleClose = () => {
        props.handleCloseDialog(false);
    };

    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.openState}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {`Vendor Name: ${props.viewData?.vendorName || ""}`}
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        <ReactJson src={props?.jsonToUse || {}}/>
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default ConfigurationRequestDialog;
