import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppService from "../../service/AppService";
import Swal from "sweetalert2";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import {Box, Tab, Tabs, Typography} from "@mui/material";
import Moment from "moment-timezone";
import {AppointmentAuditDialog} from "./AppointmentAuditDialog";
import AppliedCarrierView from "./AppliedCarrierView";


export default function AppointmentsSearchPage() {

    const [dfsSearch, setDfsSearch] = useState("");
    const [dfsData, setDfsData] = useState();
    const [error, setError] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState();
    const {execute} = useFetchWithMsal(loginRequest);
    const [tabValue, setTabValue] = useState(0);
    const [batchLastRanAt, setBatchLastRanAt] = useState();
    const [appliedCarriers, setAppliedCarriers] = useState([]);
    const [isAllCarriersLoading, setIsAllCarriersLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (tabValue === 1) {
            handleGetBatchLastRanAt();
        }
        if (tabValue === 2) {
            getAppliedCarriers();
        }
    }, [tabValue]);

    const getAppliedCarriers = () => {
        setIsAllCarriersLoading(true);
        AppService().getAppliedCarriers(execute)
            .then((response) => {
                if (response) {
                    if (response.errorMessage) {
                        setAppliedCarriers([]);
                    } else {
                        setAppliedCarriers(response);
                    }
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setIsAllCarriersLoading(false));
    };

    const handleGetBatchLastRanAt = () => {
        AppService().getLsaLastRanBatchTime(execute)
            .then((response) => {
                if (response && response !== null) {
                    const [dateTimePart, timezonePart] = response.split('[');
                    const date = new Date(dateTimePart);
                    setBatchLastRanAt(Moment(date).format("YYYY-MM-DD HH:mm:ss"));
                }
            })
            .catch((err) => console.error(err));
    };

    const handleTriggerBatchProcess = () => {
        AppService().handleTriggerLsaBatchProcess(execute).then((data) => {
            Swal.fire({
                position: "center",
                icon: "success",
                title: "LSA BATCH PROCESS",
                text: "Batch Process has started",
                showConfirmButton: true,
            });
        }).catch((err) => {
            Swal.fire({
                position: "center",
                icon: "error",
                title: "Failed to start batch process",
                text: err.message,
                showConfirmButton: true,
            });
        }).finally(() => {
            handleGetBatchLastRanAt();
            handleFetch();
        });
    };
    const handleFetch = () => {
        if (dfsSearch)
            AppService().getAllAppointmentsByDfs(execute, JSON.stringify({dfsLicenseNumber: dfsSearch})).then((data) => {
                if (data.errorMessage) {
                    setDfsData([]);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: 'Error',
                        text: data.errorMessage,
                        showConfirmButton: true,
                    });
                } else {
                    setDfsData(data);
                }
            }).catch((err) => {
                console.log("Get Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get appointment details",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    }

    const handleAction = (row) => {
        setSelectedRow(row);
        setShowDialog(true);
    }

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRow(undefined);
    }

    const handleOverrideConfirm = () => {
        setShowDialog(false);
        if (selectedRow && selectedRow.agentCarrierData) {
            const body = {
                dfsLicenseNumber: dfsData.dfsLicenseNumber,
                carrierName: selectedRow.agentCarrierData.companyName,
                isActive: selectedRow.agentCarrierData.isActive,
                isOverride: selectedRow.agentCarrierData.isOverride
            };
            setSelectedRow(undefined);
            AppService().updateAppointmentsByDfs(execute, JSON.stringify(body)).then((data) => {
                handleFetch();
            }).catch((err) => {
                console.log("Get Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to update appointment details",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
        }
    };

    return (

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "flex-start"
            }}
        >
            <Box sx={{width: '100%'}}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="DFS Search"/>
                    <Tab label="LSA Batch Trigger"/>
                    <Tab label="Applied Carrier List"/>
                </Tabs>
                {tabValue === 0 && <div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        marginTop="1rem"
                    >
                        {selectedRow &&
                            <AppointmentAuditDialog dfsSearch={dfsSearch} open={openModal} onClose={handleCloseModal}
                                                    data={selectedRow} carrierView={false}/>}
                        <Grid item xs={2}>
                            <TextField
                                name="dfsSearch"
                                id="outlined-basic"
                                label="DFS License"
                                variant="outlined"
                                required
                                value={dfsSearch}
                                onChange={(event) => setDfsSearch(event.target.value)}
                                size="small"

                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{marginLeft: "1rem"}}
                                disabled={(dfsSearch === null) || dfsSearch === undefined || dfsSearch === ''}
                                onClick={handleFetch}
                            >
                                Fetch
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            {error === '' ? (<TableContainer component={Paper} sx={{marginTop: '18px'}}>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{backgroundColor: '#0066a1 !important'}}>
                                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Carrier Name</TableCell>
                                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Appointed</TableCell>
                                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Actions</TableCell>
                                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Audit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(dfsData && (dfsData.agentCarrierWrapperData) && (dfsData.agentCarrierWrapperData.length > 0)) &&
                                            (dfsData?.agentCarrierWrapperData?.map((row) => (<TableRow
                                                key={Math.random()}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell>{row.agentCarrierData.companyName}</TableCell>
                                                <TableCell>{row.agentCarrierData.isActive ? 'Yes' : 'No'}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        style={{marginLeft: "1rem"}}
                                                        onClick={() => handleAction(row)}
                                                    >
                                                        {(row.agentCarrierData.isOverride) ? 'Remove and Accept DFS' : 'Override'}
                                                    </Button>
                                                </TableCell>
                                                <TableCell>
                                                    {row?.agentAppointmentAuditData && row.agentAppointmentAuditData.length > 0 &&
                                                        (<Button
                                                            variant="outlined"
                                                            color="info"
                                                            onClick={() => handleOpenModal(row)}
                                                        >
                                                            {'View Audit'}
                                                        </Button>)
                                                    }
                                                </TableCell>
                                            </TableRow>)))}
                                    </TableBody>
                                </Table>
                            </TableContainer>) : error}
                        </Grid>
                    </Grid>

                    <Dialog open={showDialog}>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to override the appointment?
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions sx={{display: 'flex', justifyContent: 'center'}}>
                            <Button variant="contained" color="success" onClick={handleOverrideConfirm}>Confirm</Button>
                            <Button variant="contained" color="error"
                                    onClick={() => setShowDialog(false)}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </div>}
                {tabValue === 1 && <div>
                    {batchLastRanAt &&
                        <Typography marginTop={1} variant="body1">
                            Batch Last Ran at: {batchLastRanAt}
                        </Typography>}
                    <div
                        style={{
                            fontWeight: 700,
                            fontSize: "18px",
                            color: "#2C2C2C",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{marginLeft: "1rem"}}
                            onClick={handleTriggerBatchProcess}
                        >
                            Trigger LSA Batch
                        </Button>
                    </div>
                </div>}
                {tabValue === 2 &&
                    appliedCarriers &&  <AppliedCarrierView appliedCarriers={appliedCarriers} isLoading={isAllCarriersLoading}/>
                }
            </Box>

        </div>)
}
