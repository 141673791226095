import React, {useEffect, useState} from "react";
import {Button, Grid, IconButton, Paper, Tab, Tabs, Tooltip,} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import {useAccount, useMsal} from "@azure/msal-react";
import {Edit, FileDownload} from "@mui/icons-material";
import PieChart from '../analytics/pieChart';
import jwt_decode from "jwt-decode";
import Moment from "moment-timezone";
import {parseError} from "../../util/commonValidations";
import {CampaignMaterialTable} from "./CampaignMaterialReactTable";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import {loginRequest} from "../../config/authentication/authConfig";
import AppService from "../../service/AppService";
import Loader from "../general/Loader";
import {layOutStyle} from "../../util/constants";

export default function ChartsPage() {
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});
    const [tableData, setTableData] = useState([]);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [pieOptions, setPieOptions] = useState();
    const [pieData, setPieData] = useState();
    const [totalRecords, setTotalRecords] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const {execute, isLoading} = useFetchWithMsal(loginRequest);
    const [showSubmit, setShowSubmit] = useState(false);
    const [fileToUse, setFileToUse] = useState({});
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [campaignNamesToUploadAFile, setCampaignNamesToUploadAFile] = useState([]);
    const [campaignNamesAlreadyUploaded, setCampaignNamesAlreadyUploaded] = useState([]);

    const [msalError, setMsalError] = useState(null);
    const [result, setResult] = useState();
    let dataQualityCheckForm = {
        campaignName: "",
        startDate: "",
        endDate: "",
    };
    const [defaultCampaignValue, setDefaultCampaignValue] = useState("");
    const [defaultCampaignId, setDefaultCampaignId] = useState("");
    const [editClicked, setEditClicked] = useState(true);

    const [campaignFileNames, setCampaignFileNames] = useState([]);
    const [campaignFileNamesForDownload, setCampaignFileNamesForDownload] = useState([]);

    const schemaObject = Yup.object().shape({
        campaignName: Yup.string().trim().required(),
    });

    const [searchForm, setSearchForm] = useState({
        uploadedByEmail: "",
        campaignId: "",
        campaignMappingId: "",
        campaignFileId: ""
    });
    const [validationErrors, setValidationErrors] = useState({});

    const [tabValue, setTabValue] = useState(0);
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: {errors},
        setValue,
        getValues,
    } = useForm({
        mode: "onChange",
        defaultValues: dataQualityCheckForm,
        resolver: yupResolver(schemaObject),
    });

    const watchEndDate = watch("startDate");

    useEffect(() => {
        const campaignDetails = JSON.parse(localStorage.getItem("campaignDetails"));
        console.log("CampaignDetails", campaignDetails);
        if (campaignDetails?.campaignId && campaignDetails?.campaignName) {
            setDefaultCampaignValue(campaignDetails?.campaignName);
            setDefaultCampaignId(campaignDetails?.campaignId);
            setEditClicked(false);
        }
        if (!result && account) {
            instance
                .acquireTokenSilent({
                    scopes: loginRequest.scopes,
                    account: account,
                })
                .then((result) => {
                    console.log("Result Account Use effect", result);
                    let token = result?.accessToken;
                    let decoded = jwt_decode(token);
                    console.log("Decoded Token", decoded, decoded?.emails);
                    setSearchForm({...searchForm, uploadedByEmail: decoded?.emails[0]});
                    setResult(result);
                })
                .catch((error) => {
                    console.log("Result Error", error.message);
                    setMsalError(setMsalError);
                });
        }
    }, [account, instance]);

    useEffect(() => {
        if (result) {
            getDetails();
        }
    }, [result]);

    const getDetails = () => {
        const payload = {
            limitedData: false,
        };
        setLoadingIndicator(true);
        AppService().getCampaignDetails(execute, JSON.stringify(payload))
            .then((data) => {
                console.log("Get Data Quality", data);
                if (data.campaignDetails && data.campaignDetails.length > 0) {
                    const campainDetails = data.campaignDetails;
                    const campaignArrayToUploadAFile = [];
                    const campaignArrayAlreadyUploaded = [];
                    if (campainDetails?.length > 0) {
                        campainDetails.forEach((item) => {

                            let objectToUse = {
                                key: item.campaignId,
                                value: item.campaignName
                            };
                            if (!item.fileUploaded) {
                                campaignArrayToUploadAFile.push(objectToUse);
                            } else {
                                campaignArrayAlreadyUploaded.push(objectToUse);
                            }
                        });
                    }
                    setCampaignNamesToUploadAFile(campaignArrayToUploadAFile);
                    setCampaignNamesAlreadyUploaded(campaignArrayAlreadyUploaded);
                    setLoadingIndicator(false);
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "No Campaign details available",
                        text: "No Campaign details available",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                console.log("Get Error", err.message);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "No campaign available",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    const getFilesDetails = (campaignId, isDownloadFeature) => {
        const payload = {
            campaignId: campaignId,
        };
        setSearchForm({...searchForm, campaignId: campaignId, campaignMappingId: ""});
        setLoadingIndicator(true);
        AppService().getCampaignFileDetails(execute, JSON.stringify(payload))
            .then((data) => {
                console.log("Get Data Campaign Details", data);
                if (data.campaignMappingFiles && data.campaignMappingFiles.length > 0) {
                    const campaignFileDetails = data.campaignMappingFiles;
                    const campaignFilesArray = [];
                    if (campaignFileDetails?.length > 0) {
                        campaignFileDetails.forEach((item) => {
                            let objectToUse = {
                                key: item.id,
                                value: item.campaignFileName,
                            };
                            campaignFilesArray.push(objectToUse);
                        });
                    }
                    if (isDownloadFeature) {
                        setCampaignFileNamesForDownload(campaignFilesArray);
                    } else {
                        setCampaignFileNames(campaignFilesArray);
                    }
                } else {
                    if (isDownloadFeature) {
                        setCampaignFileNamesForDownload([]);
                    } else {
                        setCampaignFileNames([]);
                    }
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "No campaign files found",
                        text: "No campaign files",
                        showConfirmButton: true,
                    });
                }
                setLoadingIndicator(false);
            })
            .catch((err) => {
                console.log("Get Error", err.message);
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get campaign details",
                    text: err.message,
                    showConfirmButton: true,
                });
            });
    };

    const handleChange = (evt) => {
        console.log("Files -->1", evt.target.files[0]);
        setLoadingIndicator(true);
        if (evt.target.files[0]) {
            const fileName = evt.target.files[0].name;
            const allowedFiles = ["csv", "xlsx"];
            const currentFileExt = fileName.split(".")[1];
            console.log("Files -->2", currentFileExt);
            if (allowedFiles.includes(currentFileExt)) {
                let f = evt.target.files[0];
                if (f) {
                    var r = new FileReader();
                    r.onload = (e) => {
                        var contents = processExcel(e.target.result);
                        console.log("contents", contents);
                        const fileData = Object.values(contents);
                        console.log(
                            "contents: fileData",
                            fileData[0],
                            fileData[0][0],
                            fileData[0][1]
                        );
                        const headersFromFile = fileData[0][0];
                        console.log("contents: headersFromFile", headersFromFile);
                        if (
                            headersFromFile.length === 3 &&
                            headersFromFile[0].toUpperCase() === "POLICY_ID" &&
                            headersFromFile[1].toUpperCase() === "POLICY_OWNER_NAME" &&
                            headersFromFile[2].toUpperCase() === "POLICY_OWNER_PHONE" &&
                            fileName.includes("UsersDetails-")
                        ) {
                            //Logic to check for valid phone numbers
                            const fileDataToUse = fileData[0].filter(
                                (item, index) => index !== 0
                            );
                            const tableData = [];
                            if (fileDataToUse.length > 0) {
                                setShowSubmit(true);
                                setFileName(fileName);
                                setFileType(currentFileExt);
                                setFileToUse(f);
                            } else {
                                Swal.fire({
                                    position: "center",
                                    icon: "error",
                                    title: "Invalid File",
                                    text: `The uploaded file doesn't have the data`,
                                    showConfirmButton: true,
                                });
                                if (document.getElementById("uploadedFile"))
                                    document.getElementById("uploadedFile").value = "";
                                setShowSubmit(false);
                                setFileType("");
                                setFileToUse({});
                            }
                        } else {
                            Swal.fire({
                                position: "center",
                                icon: "error",
                                title: "Invalid File format/Invalid columns",
                                text: `Please use the Template provided for download and valid columns and order are 
              POLICY_ID,
              POLICY_OWNER_NAME,
              POLICY_OWNER_PHONE`,
                                showConfirmButton: true,
                            });
                            document.getElementById("uploadedFile").value = "";
                            setShowSubmit(false);
                            setFileType("");
                            setFileToUse({});
                        }
                    };
                    r.readAsBinaryString(f);
                } else {
                    document.getElementById("uploadedFile").value = "";
                    setShowSubmit(false);
                    setFileType("");
                    setFileToUse({});
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Invalid file",
                        text: "Failed to load file",
                        showConfirmButton: true,
                    });
                }
            } else {
                document.getElementById("uploadedFile").value = "";
                setShowSubmit(false);
                setFileType("");
                setFileToUse({});
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Invalid file",
                    text: "Allowed file format is .csv/.xlsx",
                    showConfirmButton: true,
                });
            }
        }
        setLoadingIndicator(false);
    };

    const processExcel = (data) => {
        const workbook = XLSX.read(data, {
            type: "binary",
        });
        const firstSheet = workbook.SheetNames[0];
        const dataToUse = to_json(workbook);
        return dataToUse;
    };

    const to_json = (workbook) => {
        const result = {};
        workbook.SheetNames.forEach(function (sheetName) {
            const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
                header: 1,
            });
            if (roa.length) result[sheetName] = roa;
        });
        return result;
    };

    const phonenumber = (inputtxt) => {
        let phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (phoneno.test(inputtxt)) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmitInput = (data) => {
        console.log("Event", data);
        const campaignIdToUse = editClicked
            ? Number(data?.campaignName)
            : data?.campaignId;
        const formData = new FormData();
        formData.append("file", fileToUse);
        formData.append("fileName", fileName);
        formData.append("fileTypeUploaded", fileType);
        formData.append("campaignId", campaignIdToUse);
        //formData.append("campaignName", data?.campaignName);
        setLoadingIndicator(true);
        execute("POST", "/api/campaign/upload", formData, {campaignId: data.campaignId})
            .then((result) => {
                console.log(
                    "result for file upload now detail",
                    result,
                    result?.data?.uploadStatus
                );
                if (result?.status === "Success") {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Success",
                        text: `${result?.filename} is uploaded successfully, data will be processed in batch and invalid records will be available for review.`,
                        showConfirmButton: true
                    });
                    document.getElementById("uploadedFile").value = "";
                    setShowSubmit(false);
                    setFileType("");
                    setFileToUse({});
                    setDefaultCampaignValue(undefined);
                    setEditClicked(true);
                    reset();
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Failed",
                        text: `Failed to upload file: ` + result.errorMessages,
                        showConfirmButton: true,
                    });
                }
                getDetails();
            })
            .catch((err) => {
                setLoadingIndicator(false);
                //To DO :add logic to save the error logs
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to upload file",
                    text: `${err.message}`,
                    showConfirmButton: true,
                    //timer: 1500,
                });
                document.getElementById("uploadedFile").value = "";
                setShowSubmit(false);
                setFileType("");
                setFileToUse({});
                getDetails();
            });
    };


    const getPhoneDetailsApiCall = async (postObject) => {
        delete postObject.campaignId;
        delete postObject.campaignFileId
        setLoadingIndicator(true);
        //To Do : Get Invalid and Duplicate records based on Campaign Id and UploadeFileId
        execute(
            "POST",
            "/api/campaign/getCustomerPhoneDetails",
            JSON.stringify(postObject)
        )
            .then((data) => {
                if (data?.customerDetails && data.customerDetails.length > 0) {
                    data?.customerDetails.forEach(customerDetail => {
                       // customerDetail['errorMessage'] = Math.floor(Math.random() * 2500);
                        if (customerDetail.validNumber && customerDetail.errorMessage) {
                            customerDetail['comments'] = customerDetail.errorMessage;
                        } else if (!customerDetail.validNumber && !(customerDetail.errorMessage)) {
                            customerDetail['comments'] = 'Invalid Phone Number';
                        } else {
                            customerDetail['comments'] = '';
                        }
                    });
                    setCustomerDetails(data.customerDetails);
                    setTotalRecords(data?.numOfRecords);
                    setPageNumber(data?.pageNumber);
                    setSearchForm({
                        ...searchForm,
                        pageNumber: data?.pageNumber,
                    });
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Fetched Phone Numbers",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    setLoadingIndicator(false);
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "No Phone Numbers",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    setTableData([])
                }
            })
            .catch((err) => {
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Failed to get search results",
                    text: parseError(err),
                    showConfirmButton: true,
                });
            });
    };

    const getPhoneNumbers = async (submitClicked, newSearchForm) => {
        console.log("Get Phone submit Data", searchForm);
        let searchCriteria = Object.assign(
            {},
            newSearchForm ? newSearchForm : searchForm
        );
        await getPhoneDetailsApiCall(searchCriteria);

    };

    const downloadCampaignFile = async () => {
        const file = campaignFileNamesForDownload.find(obj => obj.key === searchForm.campaignFileId);

        try {
            setLoadingIndicator(true);
            const response = await AppService().downloadCampaignFile(execute, encodeURIComponent(file.value));
            if (response) {
                const byteCharacters = atob(response.fileBody);
                const byteNumbers = new Uint8Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const blob = new Blob([byteNumbers], {type: 'application/octet-stream'});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = file.value;
                document.body.appendChild(a);
                a.click();
                a.remove();
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "File download is in progress.",
                    showConfirmButton: false,
                    timer: 1500,
                });
                setTableData([])
            } else {
                console.error('Failed to download file');
                setLoadingIndicator(false);
                Swal.fire({
                    position: "center", icon: "error", title: "File not found.", showConfirmButton: false, timer: 1500,
                });
                setTableData([])
            }
        } catch (error) {
            console.error('Error:', error);
            setLoadingIndicator(false);

            Swal.fire({
                position: "center", icon: "error", title: "File not found.", showConfirmButton: false, timer: 1500,
            });
            setTableData([])
        }
    }

    const headerColumns = [
        {
            header: "CUSTOMER NAME",
            accessorKey: "customerName",
            enableEditing: false,
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
        },
        {
            header: "CUSTOMER PHONE",
            accessorKey: "customerPhone",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            muiTableBodyCellEditTextFieldProps: {
                error: !!validationErrors.customerPhone,
                helperText: validationErrors.customerPhone,
                required: true,
                type: "text",
                variant: "standard",
                onChange: (event) => {
                    const value = event.target.value;
                    if (!value) {
                        setValidationErrors((prev) => ({
                            ...prev,
                            customerPhone: "Customer Phone is required",
                        }));
                    } else {
                        if (phonenumber(event.target.value)) {
                            delete validationErrors.customerPhone;
                            setValidationErrors({...validationErrors});
                        } else {
                            setValidationErrors((prev) => ({
                                ...prev,
                                customerPhone: "Customer Phone is required",
                            }));
                        }
                    }
                },
            },
        },
        {
            header: "STATUS",
            accessorKey: "smsStatus",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            enableEditing: false
        },
        {
            header: "COMMENTS",
            accessorKey: "comments",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            enableEditing: false
        },
        {
            header: "CREATED ON",
            accessorKey: "createdAt",
            muiTableHeadCellProps: {
                sx: {
                    background: "#0066a1",
                    color: "#fff",
                },
            },
            enableEditing: false,
            Cell: ({cell}) => {
                return (
                    <div>{new Date(cell.getValue())?.toISOString()?.split("T")[0]}</div>
                );
            },
        },
    ];

    const handleDownloadTemplate = () => {
        //Dowmload as CSV
        let Results = [["POLICY_ID", "POLICY_OWNER_NAME", "POLICY_OWNER_PHONE"]];
        let CsvString = "";
        Results.forEach(function (RowItem, RowIndex) {
            RowItem.forEach(function (ColItem, ColIndex) {
                CsvString += ColItem + ",";
            });
            CsvString += "\r\n";
        });
        CsvString = "data:application/csv," + encodeURIComponent(CsvString);
        let x = document.createElement("A");
        x.setAttribute("href", CsvString);
        x.setAttribute(
            "download",
            `UsersDetails-${Moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")}.csv`
        );
        document.body.appendChild(x);
        x.click();

    };

    const nextPage = async (newPage) => {
        setPageNumber(newPage);
        getPhoneNumbers(true, {...searchForm, pageNumber: newPage});
    };

    const handleCampaignInput = (e) => {
        console.log("Event", e?.target);
        setSearchForm({...searchForm, [e.target.name]: e.target.value});
        const campaignId = e.target?.name;
        if (campaignId === 'campaignId') {
            getFilesDetails(e.target.value, false);
        } else if (campaignId === 'campaignIdDownload') {
            getFilesDetails(e.target.value, true);
        }
    };

    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 0.2)`;
    };

    useEffect(() => {
        const data = buildData(customerDetails);
        const result = customerDetails.reduce((x, y) => {
            (x[y.smsStatus] = x[y.smsStatus] || []).push(y);
            return x;
        }, {});
        setPieData(data);
        const pieOptions = {
            onClick: (event, elements) => {
                if (elements.length > 0) {
                    const clickedElementIndex = elements[0].index;
                    const clickedLabel = data.labels[clickedElementIndex];
                    console.log('clickedLabel', clickedLabel);
                    Object.entries(result).forEach(entry => {
                        const [key, value] = entry;
                        if (key === clickedLabel) {
                            setTableData(value);
                        }
                    });
                }
            },
        };
        setPieOptions(pieOptions);
    }, [customerDetails]);

    const showAllRows  = () => {
        setTableData(customerDetails);
    }

    const buildData = (customerDetails) => {
        let labels = [];
        let data = [];

        var result = customerDetails.reduce((x, y) => {

            (x[y.smsStatus] = x[y.smsStatus] || []).push(y);

            return x;

        }, {});

        Object.entries(result).forEach(entry => {
            const [key, value] = entry;
            console.log(key, value);
            labels.push(key);
            data.push(value.length);
        });

        const backgroundColor = labels
            .map((item) => (generateRandomColor()));

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Count',
                    data: data,
                    backgroundColor: backgroundColor,
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)'
                    ],
                    borderWidth: 1,
                },
            ]
        };
    };


    const centerStyle = {
        margin: 'auto'
    };

    const wrapStyle = {
        position: 'fixed',
        width: '100%',
        height: '100%',
        display: 'flex',
        top: 0
    }

    return (
        <div>
            {loadingIndicator && <div style={wrapStyle}>
                <div style={centerStyle}><Loader/></div>
            </div>}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    pointerEvents: loadingIndicator ? 'none' : 'all'
                }}
            >
                {(
                    <Box sx={{width: '100%'}}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Upload File"/>
                            <Tab label="Download File"/>
                            <Tab label="Campaign Status"/>
                        </Tabs>
                        <Box sx={{p: 3}}>
                            {tabValue === 0 && <div>
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                    marginBottom={"0.5rem"}
                                >
                                    <form
                                        onSubmit={handleSubmit(handleSubmitInput)}
                                        onReset={() => reset()}
                                        style={{display: "flex", flexDirection: "column", width: "100%"}}
                                    >
                                        <Paper
                                            key={"campaignForm"}
                                            variant="elevation8"
                                            style={{
                                                display: "flex",
                                                padding: "1rem",
                                                marginBottom: "1rem",
                                                width: "98%",
                                            }}
                                        >
                                            <Grid
                                                container
                                                rowSpacing={2}
                                                columnSpacing={{xs: 1, sm: 2, md: 3}}
                                            >
                                                <Grid item xs={12} s={8} md={4} lg={3}>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            padding: "1rem",
                                                            width: "65%",
                                                            background: "rgba(0, 0, 0, 0.04)",
                                                            borderRadius: "10px",
                                                            alignItems: "flex-start",
                                                            justifyContent: "space-evenly",
                                                        }}
                                                    >
                                                        <label
                                                            style={{
                                                                marginBottom: "0.25rem",
                                                                fontWeight: 500,
                                                                fontSize: "18px",
                                                            }}
                                                        >
                                                            File to upload
                                                        </label>
                                                        <form style={{width: "100%"}}>
                                                            <input
                                                                id="uploadedFile"
                                                                filename={fileToUse?.name || ""}
                                                                style={{width: "100%"}}
                                                                type="file"
                                                                name="attachments"
                                                                accept=".xlsx, .xls, .csv"
                                                                onChange={handleChange}
                                                            />
                                                        </form>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} s={8} md={4} lg={3}>
                                                    {defaultCampaignValue &&
                                                        defaultCampaignValue !== "" &&
                                                        !editClicked && (
                                                            <Box
                                                                display="flex"
                                                                alignItems={"center"}
                                                                justifyContent={"space-evenly"}
                                                            >
                                                                <div
                                                                    style={{fontWeight: 700}}
                                                                >{`Selected Campaign:`}</div>
                                                                <div style={{fontWeight: 600}}>
                                                                    {`${defaultCampaignValue}`}
                                                                </div>
                                                                <Tooltip arrow placement="right" title="Edit">
                                                                    <IconButton
                                                                        color="primary"
                                                                        onClick={() => setEditClicked(!editClicked)}
                                                                    >
                                                                        <Edit/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        )}
                                                    {editClicked && (
                                                        <Controller
                                                            control={control}
                                                            name={"campaignNameToUploadAFile"}
                                                            render={({field}) => (
                                                                <FormControl fullWidth {...field} required>
                                                                    <InputLabel id={"campaignNameToUploadAFile"}>Campaign
                                                                        Name</InputLabel>
                                                                    <Select
                                                                        {...field}
                                                                        type="select"
                                                                        label="Campaign Name"
                                                                        labelId={"campaignNameToUploadAFile"}
                                                                    >
                                                                        {campaignNamesToUploadAFile.map((item, i) => (
                                                                            <MenuItem
                                                                                value={item.key}
                                                                                name={item.value}
                                                                                key={item.key}
                                                                            >
                                                                                {item.value}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        />
                                                    )}
                                                </Grid>
                                                {showSubmit && editClicked && (
                                                    <Grid item xs={12} s={8} md={4} lg={3}>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Grid>
                                                )}
                                                {showSubmit && !editClicked && (
                                                    <Grid item xs={12} s={8} md={4} lg={3}>
                                                        <Button
                                                            //type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            onClick={() =>
                                                                handleSubmitInput({
                                                                    campaignName: defaultCampaignValue,
                                                                    campaignId: defaultCampaignId,
                                                                })
                                                            }
                                                        >
                                                            Upload
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Paper>
                                    </form>
                                    <Button
                                        variant="outlined"
                                        color="success"
                                        size="large"
                                        onClick={handleDownloadTemplate}
                                    >
                                        {"Click here to download template"}
                                        <FileDownload style={{marginLeft: "0.5rem"}}/>
                                    </Button>
                                </Box>

                            </div>
                            }
                            {tabValue === 1 && <div>
                                <div
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        color: "#2C2C2C",
                                        marginBottom: "0.5rem",
                                        marginTop: "0.5rem",
                                    }}
                                >
                                    Download File:
                                </div>
                                <Paper
                                    key={"downloadForm"}
                                    variant="elevation8"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                        //marginBottom: "1rem",
                                        width: "98%",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                        <Grid item xs={12} s={8} md={4} lg={3}>
                                            <Controller
                                                control={control}
                                                name={"campaignIdDownload"}
                                                render={({field}) => (
                                                    <FormControl fullWidth {...field} required>
                                                        <InputLabel id={"campaignIdDownload"}>Campaign Name</InputLabel>
                                                        <Select
                                                            {...field}
                                                            type="select"
                                                            label="Campaign Name"
                                                            labelId={"campaignIdDownload"}
                                                            onChange={handleCampaignInput}
                                                        >
                                                            {campaignNamesAlreadyUploaded.map((item, i) => (
                                                                <MenuItem
                                                                    value={item.key}
                                                                    name={item.value}
                                                                    key={item.key}
                                                                >
                                                                    {item.value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} s={8} md={4} lg={6}>
                                            <Controller
                                                control={control}
                                                name={"campaignFileId"}
                                                render={({field}) => (
                                                    <FormControl fullWidth {...field} required>
                                                        <InputLabel id={"campaignFileId"}>
                                                            Uploaded File Name
                                                        </InputLabel>
                                                        <Select
                                                            {...field}
                                                            type="select"
                                                            label="Uploaded File Name"
                                                            labelId={"campaignFileId"}
                                                            onChange={handleCampaignInput}
                                                        >
                                                            {campaignFileNamesForDownload.map((item, i) => (
                                                                <MenuItem
                                                                    value={item.key}
                                                                    name={item.value}
                                                                    key={item.key}
                                                                >
                                                                    {item.value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} s={4} md={2} lg={2}>
                                            <Button
                                                variant="outlined"
                                                color="success"
                                                size="large"
                                                disabled={
                                                    searchForm?.campaignFileId === ""
                                                }
                                                onClick={downloadCampaignFile}
                                            >
                                                {"Download File"}
                                                <FileDownload/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper></div>}
                            {tabValue === 2 && <div>
                                <div
                                    style={{
                                        fontWeight: 700,
                                        fontSize: "18px",
                                        color: "#2C2C2C",
                                        marginBottom: "0.5rem",
                                        marginTop: "0.5rem",
                                    }}
                                >
                                    Select a Campaign for file records
                                </div>
                                <Paper
                                    key={"invalidPhoneForm"}
                                    variant="elevation8"
                                    style={{
                                        display: "flex",
                                        padding: "1rem",
                                        //marginBottom: "1rem",
                                        width: "98%",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    <Grid container rowSpacing={2} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                                        <Grid item xs={12} s={8} md={4} lg={3}>
                                            <Controller
                                                control={control}
                                                name={"campaignId"}
                                                render={({field}) => (
                                                    <FormControl fullWidth {...field} required>
                                                        <InputLabel id={"campaignId"}>Campaign Name</InputLabel>
                                                        <Select
                                                            {...field}
                                                            type="select"
                                                            label="Campaign Name"
                                                            labelId={"campaignId"}
                                                            onChange={handleCampaignInput}
                                                        >
                                                            {campaignNamesAlreadyUploaded.map((item, i) => (
                                                                <MenuItem
                                                                    value={item.key}
                                                                    name={item.value}
                                                                    key={item.key}
                                                                >
                                                                    {item.value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} s={8} md={4} lg={3}>
                                            <Controller
                                                control={control}
                                                name={"campaignMappingId"}
                                                render={({field}) => (
                                                    <FormControl fullWidth {...field} required>
                                                        <InputLabel id={"campaignMappingId"}>
                                                            Uploaded File Name
                                                        </InputLabel>
                                                        <Select
                                                            {...field}
                                                            type="select"
                                                            label="Uploaded File Name"
                                                            labelId={"campaignMappingId"}
                                                            onChange={handleCampaignInput}
                                                        >
                                                            {campaignFileNames.map((item, i) => (
                                                                <MenuItem
                                                                    value={item.key}
                                                                    name={item.value}
                                                                    key={item.key}
                                                                >
                                                                    {item.value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} s={4} md={2} lg={2}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={
                                                    searchForm?.campaignId === "" ||
                                                    searchForm?.campaignMappingId === ""
                                                }
                                                onClick={() => getPhoneNumbers(true)}
                                            >
                                                Search
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                {
                                    (customerDetails && pieData && pieOptions) &&
                                    < Box style={layOutStyle}>
                                        <Grid container>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <PieChart data={pieData}
                                                          options={pieOptions}/>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                }
                                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                                    {!loadingIndicator && tableData?.length > 0 && (
                                        <CampaignMaterialTable
                                            headers={headerColumns}
                                            data={tableData}
                                            handleChangePage={nextPage}
                                            totalRows={totalRecords}
                                            pageNumberToUse={pageNumber}
                                            enableFilter={true}
                                            enableSort={true}
                                            enableRowActions={false}
                                            manualPagination={true}
                                            enableEditing={false}
                                            uploadAction={false}
                                            displayAllRows={true}
                                            showAllRows={showAllRows}
                                        />)}
                                </Box></div>}
                        </Box>
                    </Box>)
                }
            </div>
        </div>
    );
}
