import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Button, DialogActions, DialogContent} from "@mui/material";

export function RenewalDialog(props) {
    const {onClose, open, value} = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Vendor Identifier</DialogTitle>
            <DialogContent>
                <p>
                    {value}
                </p>
                <p>Kindly make a note of this token and securely store it for future updates to the
                    configuration.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
