import React from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import Box from "@mui/material/Box";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function App(props) {
    return (
        <Box>
            <h3>{props.name}</h3>
            <div style={{display: "flex", width: "70%", height: "70%",}}>
                <Pie data={props.data} options={props.options}/>
            </div>
        </Box>
    );
}
