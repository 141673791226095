import React from "react";
import {Typography} from "@mui/material";

export function ErrorPage({message}) {
    return (<>
        <Typography variant="h5" gutterBottom align="left">
            <b>Error Page</b>
        </Typography>
        {message &&

        <Typography variant="div" gutterBottom align="left">
            {message}
        </Typography>
        }

    </>);
}