import React, { useState, useRef, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import { MenuItem, Box, Button } from "@mui/material";
import Dialog from "../renewal-config/Dialog";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import useFetchWithMsal from "../../config/authentication/useFetchWithMsal";
import { loginRequest } from "../../config/authentication/authConfig";

export const MaterialTable = ({
  headers,
  data,
  handleChangePage,
  totalRows,
  pageNumberToUse
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [jsonData, setJsonData] = useState({});
  const { execute, isLoading } = useFetchWithMsal(loginRequest);
  const [pagination, setPagination] = useState({
    pageIndex: pageNumberToUse,
    pageSize: 25,
  });

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: headers.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  useEffect(() => {
    if (pageNumberToUse !== pagination.pageIndex) {
      handleChangePage(pagination.pageIndex);
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const getRequestDetails = async (requestId) => {
    let jsonObject = jsonData;
    await execute(
      "POST",
      "/api/admin/get-quote-request",
      JSON.stringify(Object.assign({}, { requestId: requestId }))
    )
      .then((requestBody) => {
        if (requestBody && requestBody?.requestId) {
          jsonObject["Request"] = requestBody;
          setJsonData(jsonObject);
        }
      })
      .catch((err) => {
        jsonObject["Request"] = null;
        setJsonData(jsonObject);
      });
  };

  const getResponseDetails = async (requestId) => {
    let jsonObject = jsonData;
    await execute(
      "POST",
      "/api/admin/get-quote-response",
      JSON.stringify(Object.assign({}, { requestId: requestId }))
    )
      .then((responseBody) => {
        jsonObject["Response"] = responseBody || {};
        setJsonData(jsonObject);
      })
      .catch((err) => {
        jsonObject["Response"] = null;
        setJsonData(jsonObject);
      });
  };

  const getCarrierDetails = async (requestId) => {
    let jsonObject = jsonData;
    await execute(
      "POST",
      "/api/admin/get-quote-eligibility",
      JSON.stringify(Object.assign({}, { requestId: requestId }))
    )
      .then((carrierObj) => {
        jsonObject["carrierRequest"] = carrierObj?.requestObj;
        jsonObject["carrierResponse"] = carrierObj?.responseObj;
        setJsonData(jsonObject);
      })
      .catch((err) => {
        jsonObject["carrierRequest"] = null;
        jsonObject["carrierResponse"] = null;
        setJsonData(jsonObject);
      });
  };

  const handleViewClick = async (rowData) => {
    const requestId = rowData?.id;
    if (requestId) {
      await getRequestDetails(requestId);
      await getResponseDetails(requestId);
      await getCarrierDetails(requestId);
      setOpenDialog(true);
      setSelectedRowData(rowData);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  return (
    <div style={{ width: "100%" }}>
      <Dialog
        openState={openDialog}
        viewData={selectedRowData}
        handleCloseDialog={handleClose}
        jsonToUse={jsonData}
      />
      <MaterialReactTable
        columns={headers}
        data={data}
        enableRowActions
        enableStickyHeader
        isMultiSortEvent={() => false}
        enableSorting={false}
        enableFilters={false}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            header: "Actions", //change header text
          },
        }}
        positionActionsColumn="last"
        rowCount={totalRows}
        renderRowActionMenuItems={({ row }) => [
          <MenuItem key="view" onClick={() => handleViewClick(row.original)}>
            View
          </MenuItem>,
        ]}
        enableColumnResizing
        enableRowVirtualization
        muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
        enableColumnOrdering
        enablePinning
        manualPagination
        onPaginationChange={setPagination}
        state={{ pagination }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [25],
          showFirstButton: false,
          showLastButton: false,
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              disabled={table.getRowModel().rows?.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Page Rows
            </Button>
          </Box>
        )}
      />
    </div>
  );
};

export default MaterialTable;
